import React, { useCallback } from "react";
import { Page } from "@/layout/Page";
import { TextsArraySection } from "../TextsArraySection";
import { useMutation } from "react-query";
import { location, department, currency, currencyType } from "@/queries";
import { useQ } from "@/hooks/useQ";
import { useToast } from "@/hooks/useToast";
import { MultipleSelectSection } from "../MultipleSelectSection";
import { useT } from "@/hooks/useT";

export const Properties = () => {
  const { t } = useT();
  const { data: locations = [], refetch: refetchLocations } = useQ(
    `locations`,
    () => location.list(),
  );
  const { data: departments = [], refetch: refetchDepartments } = useQ(
    `departments`,
    () => department.list(),
  );
  const { data: currencies = [], refetch: refetchCurrencies } = useQ(
    `currencies`,
    () => currency.list(),
  );
  const { data: currencyTypes = [] } = useQ(`currencyTypes`, () =>
    currencyType.list(),
  );
  const toast = useToast();

  const createLocations = useMutation(location.create, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Location created successfully");
      refetchLocations();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating location");
    },
  });

  const updateLocations = useMutation(location.update, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Location updated successfully");
      refetchLocations();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating location");
    },
  });

  const deleteLocations = useMutation(location.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Location deleted successfully");
      refetchLocations();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting location");
    },
  });

  const createDepartments = useMutation(department.create, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Department created successfully");
      refetchDepartments();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating department");
    },
  });

  const updateDepartments = useMutation(department.update, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Department updated successfully");
      refetchDepartments();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating department");
    },
  });

  const deleteDepartments = useMutation(department.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Department deleted successfully");
      refetchDepartments();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting department");
    },
  });

  const createCurrencies = useMutation(currency.create, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Currency created successfully");
      refetchCurrencies();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating currency");
    },
  });

  return (
    <Page>
      <TextsArraySection
        title={t("accountSetting.properties.locations.title")}
        description={t("accountSetting.properties.locations.description")}
        items={locations}
        onNewItemSubmit={createLocations.mutate}
        onEditItemSubmit={updateLocations.mutate}
        onDeleteItemSubmit={deleteLocations.mutate}
      />
      <TextsArraySection
        title={t("accountSetting.properties.departments.title")}
        description={t("accountSetting.properties.departments.description")}
        items={departments}
        onNewItemSubmit={createDepartments.mutate}
        onEditItemSubmit={updateDepartments.mutate}
        onDeleteItemSubmit={deleteDepartments.mutate}
      />
      <MultipleSelectSection
        title={t("accountSetting.properties.currencies.title")}
        description={t("accountSetting.properties.currencies.description")}
        label={t("accountSetting.properties.currencies.form.title")}
        placeholder={t("accountSetting.properties.currencies.form.description")}
        items={currencies}
        options={currencyTypes.map((currencyType) => ({
          value: currencyType.id,
          label: `${currencyType.id} - ${currencyType.title}`,
        }))}
        onNewItemSubmit={({ itemId }) =>
          createCurrencies.mutate({ currencyId: itemId })
        }
      />
    </Page>
  );
};
