import React from "react";
import { AccordionItem } from "@/base/AccordionItem";
import { Section } from "@/layout/Section";
import { Typography } from "@mui/material";
import moment from "moment";
import { useT } from "@/hooks/useT";

interface Report {
  date: string;
  type: string;
  initiative?: { name: string };
  exception?: boolean;
}

interface UpdatedReportsProps {
  reports: Report[];
  onClick: (report: Report) => void;
  t: (report: string) => string;
}

function reportTypeName(type: string): string {
  const { t } = useT();
  return (
    {
      highlight: t("project.progress.highlightReport.title"),
      checkpoint: t("project.progress.checkpointReport.title"),
      issue: t("project.change.issueRegister.issueReport"),
      issueException: "Exception Issue Report",
      brief: t("project.navigation.reports.brief"),
      projectEnd: t("project.progress.endProject.title"),
      stagePlan: t("project.plans.schedule.details.title"),
      pid: "PID",
      releasePlan: t("product.plans.schedule.details.title"),
      endStage: t("project.progress.endStage.title"),
    }[type] ?? ""
  );
}

export const UpdatedReports: React.FC<UpdatedReportsProps> = ({
  reports,
  onClick,
  t,
}) => (
  <Section title={t("page.home.reports.title")}>
    {reports !== undefined && reports.length > 0 ? (
      reports
        .sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0))
        .slice(0, 4)
        .map((report, index) => (
          <AccordionItem
            key={index}
            title={report?.initiative?.name ?? ""}
            subtitle={`${reportTypeName(
              report.type === "issue"
                ? report.exception
                  ? "issueException"
                  : report.type
                : report.type,
            )} (${moment(report?.date).format("YYYY-MM-DD")})`}
            onClick={() => onClick(report)}
            open={false}
          />
        ))
    ) : (
      <Typography variant="caption">
        {t("page.home.reports.noReports")}
      </Typography>
    )}
  </Section>
);
