import axios from "axios";
//import { path } from "ramda";

export const client = axios.create({
  baseURL: "/api",
  timeout: 15000,
});

export const restClient = axios.create({
  baseURL: "/api/v1",
  timeout: 15000,
});

const handleResponseError = (error) => {
  const status = error.response?.status;
  const method = error.config.method;
  if (
    status === 401 &&
    method !== "get" &&
    !window.location.pathname.includes("/login") &&
    !window.location.pathname.includes("/401")
  ) {
    console.log("Redirecting to /401");
    window.open("/401", "_blank", "noopener,noreferrer");
  }

  return Promise.reject(error);
};

client.interceptors.response.use((response) => response, handleResponseError);

restClient.interceptors.response.use(
  (response) => response,
  handleResponseError,
);
/*
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.method === "get") {
      if (path(["response", "status"], error) === 500) {
        window.location = "/500";
      }
      if (path(["response", "status"], error) === 404) {
        window.location = "/404";
      }
      if ([401, 403].includes(path(["response", "status"], error))) {
        if (location.pathname !== "/login" || location.pathname !== "/login/") {
          window.location = "/401";
        } else {
          console.log("[TRACE] Is already on loginpage, ignore rediret to 401");
        }
      }
    } else {
      if ([401, 403].includes(path(["response", "status"], error))) {
        window.open("/login", "_blank", "noopener,noreferrer");
        window.alert("Log in on the opened tab and try again.");
      }
    }
    return Promise.reject(error);
  },
);

restClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config.method === "get") {
      if (path(["response", "status"], error) === 500) {
        window.location = "/500";
      }
      if (path(["response", "status"], error) === 404) {
        window.location = "/404";
      }
      if ([401, 403].includes(path(["response", "status"], error))) {
        if (location.pathname !== "/login" || location.pathname !== "/login/") {
          window.location = "/401";
        } else {
          console.log("[TRACE] Is already on loginpage, ignore rediret to 401");
        }
      }
    } else {
      if ([401, 403].includes(path(["response", "status"], error))) {
        window.open("/login", "_blank", "noopener,noreferrer");
        window.alert("Log in on the opened tab and try again.");
      }
    }
    return Promise.reject(error);
  },
);
*/
