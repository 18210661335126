import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { useT } from "@/hooks/useT";
import { CustomAccordion } from "@/layout/CustomAccordion";
import {
  Typography,
  Stack,
  Button,
  Paper,
  Box,
  Stepper,
  Step,
  StepButton,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Link,
  Checkbox,
  TextField,
  InputAdornment,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQ } from "@/hooks/useQ";
import {
  project,
  location,
  portfolio,
  projectType,
  department,
  category,
} from "@/queries";
import EditIcon from "@mui/icons-material/Edit";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { FormSelect } from "@/base/form/mui/FormSelect";
import { FormRichTextField } from "@/base/form/mui/FormRichTextField";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { v4 as uuidv4 } from "uuid";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePretty } from "@/base/DatePretty";
import moment from "moment/moment";
import { isEmpty, isNil } from "ramda";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useFlag } from "../../hooks/useFlag";
import { ExpandLess, UnfoldMore } from "@mui/icons-material";
import { FormCheckboxGroup } from "@/base/form/mui/FormCheckboxGroup";
import { toast } from "react-toastify";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { StepperSections } from "@/base/form/mui/StepperSections";
import { CustomDialog } from "@/layout/CustomDialog";
import { StepperNavigator } from "@/base/form/mui/StepperNavigator";

const ReadOnly = ({
  projectData,
  lesson,
  refetch,
  isApplied,
  onDelete,
  setShowEdit,
  showEdit,
}) => {
  const [t] = useT();
  const toast = useToast();
  const isArchived = lesson?.archived?.find((e) => e === projectData?.id);
  const useLessonArchive = useFlag("project.lesson.archive");
  const useRestApiV1 = useFlag("lessons.useRestApiV1");

  const archiveLesson = useMutation(
    useRestApiV1 ? project.archiveLesson : project._archiveLessonDeprecated,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Successfully archived lesson");
        refetch();
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error archiving lesson");
      },
    },
  );

  const unarchiveLesson = useMutation(
    useRestApiV1 ? project.unarchiveLesson : project._unarchiveLessonDeprecated,
    {
      onSuccess: (data) => {
        console.log("Received data: " + JSON.stringify(data));
        toast.success("Successfully removed lesson from archive");
        refetch();
      },
      onError: (error) => {
        console.log("Received error: " + JSON.stringify(error));
        toast.error("Error unarchiving lesson");
      },
    },
  );

  return (
    <Stack justifyContent="flex-end" direction="row" sx={{ mb: 2 }} spacing={2}>
      {(isApplied || projectData?.id === lesson?.source?.id) &&
        useLessonArchive &&
        (!isArchived ? (
          <IconButton
            onClick={() =>
              archiveLesson.mutate({
                projectId: projectData.id,
                lessonId: lesson.id,
                lesson: {
                  ...lesson,
                  archive: true,
                },
              })
            }
          >
            <ArchiveIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() =>
              unarchiveLesson.mutate({
                projectId: projectData.id,
                lessonId: lesson.id,
                lesson: {
                  ...lesson,
                  unarchive: true,
                },
              })
            }
          >
            <UnarchiveIcon />
          </IconButton>
        ))}
      {projectData?.id === lesson?.source?.id && (
        <>
          {isArchived && (
            <IconButton onClick={() => onDelete()}>
              <DeleteIcon />
            </IconButton>
          )}
          <Button
            variant="text"
            color="secondary"
            startIcon={<EditOutlinedIcon />}
            onClick={() => setShowEdit(!showEdit)}
            data-cy="edit-stage-button"
          >
            {t("project.plans.schedule.details.edit")}
          </Button>
        </>
      )}
    </Stack>
  );
};

const EditLesson = ({ lesson, projectData, projects, readOnly, isApplied }) => {
  const { t } = useT();
  const { data: thisProjectData = {} } =
    lesson?.source !== undefined
      ? useQ(
          `project-${lesson?.source?.id}`,
          () => project.single({ id: lesson?.source?.id }),
          {
            enabled: lesson?.source?.id && projectData.id !== lesson.source.id,
          },
        )
      : { data: {} };

  const applyLesson = useMutation(project.applyLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Lesson successfully applied");
      refetch();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error applying lesson");
    },
  });

  const unapplyLesson = useMutation(project.unapplyLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Lesson successfully unapplied");
      refetch();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error lesson unapplying");
    },
  });

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h1">{lesson?.title}</Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography>{`"${lesson?.effect ?? ""}" (${t(
            "project.plans.lessons.form.statement.effect",
          )}) ${t("project.plans.lessons.form.statement.because")} "${
            lesson?.event ?? ""
          }" (${t("project.plans.lessons.form.statement.event")}). ${t(
            "project.plans.lessons.form.statement.thisWasCausedBy",
          )} "${lesson?.cause ?? ""}" (${t(
            "project.plans.lessons.form.statement.cause",
          )})`}</Typography>
        </Stack>
        <Typography dangerouslySetInnerHTML={{ __html: lesson?.description }} />
        <Stack spacing={1}>
          <Typography variant="h2">
            {t("project.plans.lessons.form.indicators.title")}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: lesson?.indicators }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h2">
            {t("project.plans.lessons.form.recommendations.title")}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: lesson?.recommendations }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h2">
            {t("project.plans.lessons.form.type.title")}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t(
                `project.plans.lessons.form.type.options.${lesson?.type}`,
              ).includes("`project.plans.lessons.form.type.options.")
                ? ""
                : t(`project.plans.lessons.form.type.options.${lesson?.type}`),
            }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h2">
            {t("project.plans.lessons.form.priority.title")}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t(
                `project.plans.lessons.form.priority.options.${lesson?.priority}`,
              ).includes("`project.plans.lessons.form.priority.options.")
                ? ""
                : t(
                    `project.plans.lessons.form.priority.options.${lesson?.priority}`,
                  ),
            }}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h2">
            {t("project.plans.lessons.form.source.title")}
          </Typography>
          {lesson?.source !== undefined ? (
            <Link href={`/projects/${lesson?.source?.id}`} color={"primary"}>
              {projectData.id !== lesson?.source?.id
                ? thisProjectData?.name
                : projectData?.name}
            </Link>
          ) : (
            "????"
          )}
        </Stack>
        {lesson?.applied && !isEmpty(lesson?.applied) && (
          <Stack>
            <Typography variant="h2">
              {t("project.plans.lessons.form.appliedIn.title")}
            </Typography>
            {projects.map(
              (project) =>
                lesson?.applied?.includes(project.id) && (
                  <Link href={`/projects/${project.id}`} color={"primary"}>
                    {project.name}
                  </Link>
                ),
            )}
          </Stack>
        )}
      </Stack>
      {!readOnly &&
        lesson?.source?.id !== projectData?.id &&
        (!isApplied ? (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
            width={"100%"}
          >
            <Button
              variant="contained"
              onClick={() =>
                applyLesson.mutate({
                  projectId: projectData.id,
                  lessonId: lesson.id,
                })
              }
            >
              {t("project.plans.lessons.actions.apply")}
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
            width={"100%"}
          >
            <Button
              variant="contained"
              onClick={() =>
                unapplyLesson.mutate({
                  projectId: projectData.id,
                  lessonId: lesson.id,
                })
              }
            >
              {t("project.plans.lessons.actions.unapply")}
            </Button>
          </Stack>
        ))}
    </Paper>
  );
};

export const LessonDetails = ({
  projectData,
  lesson,
  onSubmit,
  onDelete,
  refetch,
  readOnly,
  projects,
  open,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  const isApplied = lesson?.applied?.find((e) => e === projectData?.id);

  return (
    <Stack>
      {!readOnly && (
        <ReadOnly
          projectData={projectData}
          lesson={lesson}
          refetch={refetch}
          isApplied={isApplied}
          onDelete={onDelete}
          setShowEdit={setShowEdit}
          showEdit={showEdit}
        />
      )}
      <EditLesson
        lesson={lesson}
        projectData={projectData}
        isApplied={isApplied}
        projects={projects}
        setShowEdit={setShowEdit}
        readOnly={readOnly}
      />
      <LessonForm
        open={showEdit}
        projectData={projectData}
        lesson={lesson}
        onCancel={() => setShowEdit(false)}
        onSubmit={onSubmit}
      />
    </Stack>
  );
};

export const LessonForm = ({
  projectData,
  lesson,
  onSubmit,
  onCancel,
  open,
}) => {
  const { t } = useT();
  const schema = yup.object().shape({
    id: yup.string().optional(),
    title: yup.string().required(t("project.plans.lessons.form.title.missing")),
    type: yup.string().required(t("project.plans.lessons.form.type.missing")),
    priority: yup
      .string()
      .required(t("project.plans.lessons.form.priority.missing")),
    effect: yup.string().optional(),
    event: yup.string().optional(),
    cause: yup.string().optional(),
    description: yup.string().optional(),
    indicators: yup.string().optional(),
    recommendations: yup.string().optional(),
  });
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: lesson?.id ?? "",
      title: lesson?.title ?? "",
      type: lesson?.type ?? "",
      priority: lesson?.priority ?? "",
      effect: lesson?.effect ?? "",
      event: lesson?.event ?? "",
      cause: lesson?.cause ?? "",
      description: lesson?.description ?? "",
      indicators: lesson?.indicators ?? "",
      recommendations: lesson?.recommendations ?? "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const nextStep = useRef(null);

  const isCreating = !lesson;

  const showPreviousButton = useMemo(() => {
    return !isCreating && activeStep > 0;
  }, [isCreating, activeStep]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors },
  } = formData;

  const [title] = useWatch({
    control,
    name: ["title"],
  });

  useEffect(() => {
    if (errors.title || errors.type || errors.priority) {
      setActiveStep(0);
    }
  }, [errors]);

  useEffect(() => {
    reset({
      id: lesson?.id ?? "",
      title: lesson?.title ?? "",
      type: lesson?.type ?? "",
      priority: lesson?.priority ?? "",
      effect: lesson?.effect ?? "",
      event: lesson?.event ?? "",
      cause: lesson?.cause ?? "",
      description: lesson?.description ?? "",
      indicators: lesson?.indicators ?? "",
      recommendations: lesson?.recommendations ?? "",
    });
    if (isCreating) {
      setActiveStep(0);
    } else if (nextStep.current !== null) {
      setActiveStep(nextStep.current);
      nextStep.current = null;
    }
  }, [reset, open, lesson]);

  const getStepIndex = (step) => {
    return ["summary", "details", "follow-up"].indexOf(step);
  };

  const lessonsTypes = useMemo(
    () => [
      "project",
      ...(projectData?.programme ? ["programme"] : []),
      "portfolio",
      "corporate",
    ],
    [projectData],
  );
  const lessonsPriorities = ["high", "medium", "low"];

  const [effect, event, cause] = useWatch({
    control,
    name: ["effect", "event", "cause"],
  });

  const steps = useMemo(() => ["summary", "details", "follow-up"], []);

  const submitButtonLabel = useMemo(() => {
    if (isCreating) {
      return t("project.plans.lessons.actions.create");
    } else if (!isDirty) {
      if (activeStep === 0) {
        return !title?.length ? false : t("risk.registerForm.buttons.next");
      } else if (activeStep === steps.length - 1) {
        return false;
      }
      return t("risk.registerForm.buttons.next");
    } else {
      if (activeStep === 0) {
        return !title?.length
          ? t("risk.registerForm.buttons.update")
          : t("risk.registerForm.buttons.updateAndNext");
      } else if (activeStep === steps.length - 1) {
        return t("risk.registerForm.buttons.update");
      } else {
        return t("risk.registerForm.buttons.updateAndNext");
      }
    }
  }, [isCreating, activeStep, isDirty, title]);

  const triggerSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        if (isDirty) {
          await onSubmit(data);
          nextStep.current = Math.min(steps.length - 1, activeStep + 1);
        } else {
          setActiveStep((a) => a + 1);
        }
      }),
    [handleSubmit, onSubmit, steps, reset, isDirty, activeStep],
  );

  return (
    <CustomDialog
      isOpen={open}
      onClose={onCancel}
      scrollDependencyArray={[activeStep]}
    >
      <FormDataProvider formKey="project.plans.lessons.form" {...formData}>
        <form onSubmit={triggerSubmit}>
          <StepperSections
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            formatLabel={(step) => t(`project.plans.lessons.${step}.title`)}
          />
          <Box sx={{ mt: 4 }}>
            {activeStep === getStepIndex("summary") && (
              <Stack spacing={2}>
                <FormTextField name="title" required />
                <FormSelect
                  name="type"
                  required
                  options={lessonsTypes.map((item) => ({
                    value: item,
                    label: t("project.plans.lessons.form.type.options." + item),
                  }))}
                />
                <FormSelect
                  name="priority"
                  required
                  options={lessonsPriorities.map((item) => ({
                    value: item,
                    label: t(
                      "project.plans.lessons.form.priority.options." + item,
                    ),
                  }))}
                />
              </Stack>
            )}
            {activeStep === getStepIndex("details") && (
              <Stack spacing={2}>
                <Typography>{`"${effect}" (${t(
                  "project.plans.lessons.form.statement.effect",
                )}) ${t(
                  "project.plans.lessons.form.statement.because",
                )} "${event}" (${t(
                  "project.plans.lessons.form.statement.event",
                )}). ${t(
                  "project.plans.lessons.form.statement.thisWasCausedBy",
                )} "${cause}" (${t(
                  "project.plans.lessons.form.statement.cause",
                )})`}</Typography>
                <FormTextField name="effect" />
                <FormTextField name="event" />
                <FormTextField name="cause" />
                <FormRichTextField name="description" />
              </Stack>
            )}
            {activeStep === getStepIndex("follow-up") && (
              <Stack spacing={2}>
                <FormRichTextField name="indicators" />
                <FormRichTextField name="recommendations" />
              </Stack>
            )}
          </Box>
          <StepperNavigator
            showPreviousButton={showPreviousButton}
            setActiveStep={setActiveStep}
            onCancel={onCancel}
            isDirty={isDirty}
            submitButtonLabel={submitButtonLabel}
          />
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const Lessons = ({ lessonsFrom, readOnly = false }) => {
  const { t } = useT();
  const { id = "" } = useParams();
  const { data: projectData = {} } = useQ(
    `project-${id}`,
    () => project.single({ id }),
    { enabled: !readOnly },
  );

  const { data: projectDataList = [] } = useQ(
    `projects`,
    () => project.list(),
    { enabled: !readOnly },
  );

  const { data: locations = [] } = useQ(`locations`, () => location.list(), {
    enabled: !readOnly,
  });
  const { data: portfolios = [] } = useQ(`portfolios`, () => portfolio.list(), {
    enabled: !readOnly,
  });
  const { data: types = [] } = useQ(`projectType`, () => projectType.list(), {
    enabled: !readOnly,
  });
  const { data: departments = [] } = useQ(
    `departments`,
    () => department.list(),
    {
      enabled: !readOnly,
    },
  );
  const { data: categories = [] } = useQ(`categories`, () => category.list(), {
    enabled: !readOnly,
  });

  const { data: projectLessonsData = {}, refetch: refetchProjectLessons } =
    useQ(`project-lessons`, () => project.lessons());

  const lessons = projectLessonsData.lessons ?? [];

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [showAppliedOnly, setShowAppliedOnly] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [searchForLessons, setSearchForLessons] = useState(readOnly);

  const [showLessonsFrom, setShowLessonsFrom] = useState(
    lessonsFrom ?? "portfolio",
  );

  const [sortBy, updateSortBy] = useState({});

  const handleSort = (sortValue) => {
    if (sortBy.sortedOn === sortValue) {
      updateSortBy({ sortedOn: sortValue, sortAsc: !sortBy.sortAsc });
    } else {
      updateSortBy({ sortedOn: sortValue, sortAsc: true });
    }
  };

  const [projectList, setProjectList] = useState([]);
  const [query, updateQuery] = useState("");
  const [searchedLessons, setSearchedLessons] = useState("");

  const schema = yup.object().shape({
    location: yup.array().required(),
    portfolio: yup.array().required(),
    department: yup.array().required(),
    state: yup.array().required(),
    time: yup.array().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      location: locations.map((item) => item.name),
      portfolio: portfolios.map((item) => item.name),
      projectType: types.map((item) => item.name),
      department: departments.map((item) => item.name),
      category: categories.map((item) => item.name),
      state: ["isStarted", "isPaused", "isStopped", "isCompleted"],
      time: ["newer", "between1and3", "between3and5", "older"],
    },
  });

  const { control, setValue } = formData;

  const [
    locationList,
    portfolioList,
    typeList,
    departmentList,
    categoryList,
    state,
    time,
  ] = useWatch({
    control,
    name: [
      "location",
      "portfolio",
      "projectType",
      "department",
      "category",
      "state",
      "time",
    ],
  });

  const isStarted = state.includes("isStarted");
  const isPaused = state.includes("isPaused");
  const isStopped = state.includes("isStopped");
  const isCompleted = state.includes("isCompleted");

  const newer = time.includes("newer");
  const between1and3 = time.includes("between1and3");
  const between3and5 = time.includes("between3and5");
  const older = time.includes("older");

  useEffect(() => {
    setValue(
      "location",
      locations.map((item) => item.name),
    );
    setValue(
      "portfolio",
      portfolios.map((item) => item.name),
    );
    setValue(
      "projectType",
      types.map((item) => item.name),
    );
    setValue(
      "department",
      departments.map((item) => item.name),
    );
    setValue(
      "category",
      categories.map((item) => item.name),
    );
  }, [locations, portfolios, types, departments, categories]);

  useEffect(() => {
    const projectProps = projectDataList?.map((projectData) => {
      return {
        id: projectData.uri.replace("/projects/", ""),
        status: projectData?.projectState,
        portfolio: projectData?.portfolio,
        ...projectData.attributes,
      };
    });

    const filteredProjects =
      projectProps
        .filter((prop) => {
          return (
            ((isStarted && prop.status === "Running") ||
              (isPaused && prop.status === "Paused") ||
              (isStopped && prop.status === "Stopped") ||
              (isCompleted && prop.status === "Completed")) &&
            (locationList.includes(prop.location) || isNil(prop.location)) &&
            portfolioList.includes(prop.portfolio?.name) &&
            (typeList.includes(prop.type) || isNil(prop.type)) &&
            (departmentList.includes(prop.department) ||
              isNil(prop.department)) &&
            (categoryList.includes(prop.category) || isNil(prop.category))
          );
        })
        .map((item) => item.id) ?? [];
    setProjectList(filteredProjects);
  }, [
    projectDataList,
    lessons,
    useWatch({
      control,
    }),
  ]);

  useEffect(() => {
    if (lessonsFrom !== undefined) {
      setShowLessonsFrom(lessonsFrom);
    }
  }, [lessonsFrom]);

  const toast = useToast();

  const useRestApiV1 = useFlag("lessons.useRestApiV1");

  const addLesson = useMutation(project.addLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Lesson created successfully");
      setShowNewItemForm(false);
      refetchProjectLessons();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating lesson");
    },
  });

  const updateLesson = useMutation(project.updateLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Lesson updated successfully");
      refetchProjectLessons();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating lesson");
    },
  });

  const deleteItem = useMutation(project.deleteLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Deleted successfully");
      refetchProjectLessons();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting");
    },
  });

  const handleUpdateLesson = useCallback(
    async (data) => {
      await updateLesson.mutate({
        useRestApiV1,
        projectId: id,
        lessonId: data.id,
        lesson: {
          ...lessons.find((s) => s.id === data.id),
          ...data,
        },
      });
    },
    [id, useRestApiV1],
  );

  const [newLessonId, setNewLessonId] = useState(null);

  const handleCreateLesson = useCallback(
    async (data) => {
      const lessonId = uuidv4();
      setNewLessonId(lessonId);
      await addLesson.mutate({
        useRestApiV1,
        projectId: id,
        lesson: {
          ...data,
          id: lessonId,
        },
      });
    },
    [id, useRestApiV1],
  );

  const foundLessons = useMemo(() => {
    let lessonsFrom = lessons;
    switch (showLessonsFrom) {
      case "project":
        lessonsFrom = lessons.filter(
          (lesson) =>
            (lesson.type === "project" && lesson?.source?.id === id) ||
            (lesson.type === "project" && readOnly),
        );
        break;

      case "portfolio":
        lessonsFrom = lessons.filter(
          (lesson) =>
            (!showAppliedOnly
              ? lesson?.source?.id !== id &&
                !(
                  lesson.type === "portfolio" &&
                  lesson?.applied?.find((e) => e === id)
                )
              : true) &&
            ((lesson.type === "portfolio" &&
              lesson?.applied?.find((e) => e === id)) ||
              (lesson.type === "portfolio" && readOnly) ||
              (lesson.type === "portfolio" && lesson?.source?.id === id) ||
              (lesson.type === "portfolio" &&
                lesson?.portfolio === projectData?.portfolio)),
        );
        break;

      case "program":
        lessonsFrom = lessons.filter(
          (lesson) =>
            (!showAppliedOnly
              ? lesson?.source?.id !== id &&
                !(
                  lesson.type === "programme" &&
                  lesson?.applied?.find((e) => e === id)
                )
              : true) &&
            ((lesson.type === "programme" &&
              lesson?.applied?.find((e) => e === id)) ||
              (lesson.type === "programme" && readOnly) ||
              (lesson.type === "programme" && lesson?.source?.id === id) ||
              (lesson.type === "programme" && !showAppliedOnly)),
        );
        break;

      case "corporate":
        lessonsFrom = lessons.filter(
          (lesson) =>
            (!showAppliedOnly
              ? lesson?.source?.id !== id &&
                !(
                  lesson.type === "corporate" &&
                  lesson?.applied?.find((e) => e === id)
                )
              : true) &&
            ((lesson.type === "corporate" &&
              lesson?.applied?.find((e) => e === id)) ||
              (lesson.type === "corporate" && readOnly) ||
              (lesson.type === "corporate" && lesson?.source?.id === id) ||
              (lesson.type === "corporate" && !showAppliedOnly)),
        );
        break;

      default:
        lessonsFrom = lessons;
        break;
    }
    return lessonsFrom;
  }, [
    showLessonsFrom,
    lessons,
    searchForLessons,
    showAppliedOnly,
    projectList,
    query,
    sortBy,
    showArchived,
  ]);

  const relevantLessons = useMemo(() => {
    return searchForLessons
      ? foundLessons
      : lessons.filter(
          (lesson) =>
            lesson?.source?.id === id ||
            lesson?.applied?.find((e) => e === id || e?.id === id) ||
            (readOnly && lesson.type === "project"),
        );
  }, [foundLessons]);

  const filteredLessons = useMemo(() => {
    return relevantLessons
      .filter(
        (lesson) =>
          projectList.includes(lesson?.source?.id) ||
          readOnly ||
          !searchForLessons,
      )
      .filter((lesson) => {
        const startDate = moment(lesson.identified);
        const age = moment(new Date()).diff(startDate, "years");
        return (
          (newer && age < 1) ||
          (between1and3 && age >= 1 && age < 3) ||
          (between3and5 && age >= 3 && age <= 5) ||
          (older && age > 5)
        );
      })
      .filter((lesson) =>
        query !== "" ? searchedLessons.includes(lesson.id) : true,
      )
      .filter((lesson) => showArchived || !lesson?.archived?.includes(id));
  }, [relevantLessons]);

  const sortedLessons = useMemo(() => {
    return filteredLessons
      .sort(function (a, b) {
        return sortBy.sortedOn === "priority"
          ? sortBy.sortAsc
            ? (a.priority === "high" ? 2 : a.priority === "medium" ? 1 : 0) -
              (b.priority === "high" ? 2 : b.priority === "medium" ? 1 : 0)
            : (b.priority === "high" ? 2 : b.priority === "medium" ? 1 : 0) -
              (a.priority === "high" ? 2 : a.priority === "medium" ? 1 : 0)
          : sortBy.sortedOn === "date"
            ? sortBy.sortAsc
              ? moment(b.identified).diff(moment(a.identified))
              : moment(a.identified).diff(moment(b.identified))
            : null;
      })
      .sort(function (a, b) {
        return (
          a.archived?.includes(id) ||
          a.archived === undefined - b.archived?.includes(id) ||
          b.archived === undefined
        );
      });
  }, [filteredLessons]);

  useEffect(() => {
    console.log({ lessons, newLessonId });
    if (newLessonId && lessons.find((l) => newLessonId === l.id)) {
      setEditingId(newLessonId);
      setNewLessonId(null);
    }
  }, [lessons, newLessonId]);

  return (
    <Page isLoading={false} title={"Plans - Lessons"}>
      <Section title={t("project.plans.lessons.title")}>
        {!readOnly ? (
          <Stack direction="column">
            <Stack direction="row" width={"100%"}>
              {searchForLessons ? (
                <Collapse in={searchForLessons}>
                  <RadioGroup
                    defaultValue="portfolio"
                    name="radio-buttons-group"
                    row
                    sx={{ width: "100%" }}
                    value={showLessonsFrom}
                    onChange={(event) => setShowLessonsFrom(event.target.value)}
                  >
                    <Stack direction={"row"} sx={{ width: "100%" }}>
                      <Stack
                        direction="row"
                        sx={{ width: "75%", whiteSpace: "nowrap" }}
                      >
                        <FormControlLabel
                          value="portfolio"
                          control={<Radio />}
                          label={t("project.plans.lessons.filter.portfolio")}
                        />
                        {projectData.programme ? (
                          <FormControlLabel
                            value="program"
                            control={<Radio />}
                            label={t("project.plans.lessons.filter.program")}
                          />
                        ) : null}
                        <FormControlLabel
                          value="corporate"
                          control={<Radio />}
                          label={t("project.plans.lessons.filter.corporate")}
                          sx={{ position: "relative" }}
                        />
                        {showLessonsFrom !== "project" ? (
                          <Stack direction="row">
                            <Stack
                              sx={{ width: "100%", cursor: "pointer" }}
                              onClick={() => {
                                setShowAppliedOnly(!showAppliedOnly);
                              }}
                              direction="row"
                            >
                              <Typography sx={{ mt: 1.5 }}>
                                {t("project.plans.lessons.filter.appliedOnly")}
                              </Typography>
                              <Checkbox checked={showAppliedOnly} />
                            </Stack>
                            {showAppliedOnly && (
                              <Stack
                                sx={{ width: "100%", cursor: "pointer" }}
                                onClick={() => {
                                  setShowArchived(!showArchived);
                                }}
                                direction="row"
                              >
                                <Typography sx={{ mt: 1.5 }}>
                                  {t("project.plans.lessons.filter.archived")}
                                </Typography>
                                <Checkbox checked={showArchived} />
                              </Stack>
                            )}
                          </Stack>
                        ) : null}
                      </Stack>
                    </Stack>
                  </RadioGroup>
                </Collapse>
              ) : (
                <Stack
                  sx={{ width: "100%", cursor: "pointer" }}
                  onClick={() => {
                    setShowArchived(!showArchived);
                  }}
                  direction="row"
                >
                  <Typography sx={{ mt: 2 }}>
                    {t("project.plans.lessons.filter.archived")}
                  </Typography>
                  <Checkbox checked={showArchived} />
                </Stack>
              )}
              <Box sx={{ width: "100%" }} />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                mb={2}
              >
                {!readOnly &&
                  (!searchForLessons ? (
                    <IconButton
                      onClick={() => {
                        setSearchForLessons(true);
                        setShowAppliedOnly(false);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setSearchForLessons(false);
                      }}
                    >
                      <SearchOffIcon />
                    </IconButton>
                  ))}
                <Button
                  variant="text"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => setShowNewItemForm((s) => !s)}
                >
                  {showNewItemForm
                    ? t("generic.button.cancel")
                    : t("generic.button.add")}
                </Button>
              </Stack>
            </Stack>

            <Collapse in={searchForLessons && !showAppliedOnly}>
              {!showAppliedOnly && (
                <FormDataProvider
                  formKey="risk.registerForm.fields"
                  {...formData}
                >
                  <form>
                    <Stack>
                      <Stack
                        direction="row"
                        width={"100%"}
                        useFlexGap
                        flexWrap="wrap"
                      >
                        <Stack direction="column" sx={{ whiteSpace: "nowrap" }}>
                          <FormCheckboxGroup
                            name={"state"}
                            label={t(
                              "project.plans.lessons.filter.fields.state.title",
                            )}
                            help={""}
                            items={[
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.state.options.started",
                                ),
                                name: "isStarted",
                                value: isStarted,
                              },
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.state.options.paused",
                                ),
                                name: "isPaused",
                                value: isPaused,
                              },
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.state.options.stopped",
                                ),
                                name: "isStopped",
                                value: isStopped,
                              },
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.state.options.completed",
                                ),
                                name: "isCompleted",
                                value: isCompleted,
                              },
                            ]}
                          />
                        </Stack>
                        {!isEmpty(portfolios) &&
                          showLessonsFrom === "corporate" && (
                            <Box sx={{ whiteSpace: "nowrap" }}>
                              <FormCheckboxGroup
                                name={"portfolio"}
                                help={""}
                                label={t(
                                  "project.plans.lessons.filter.fields.portfolio.title",
                                )}
                                items={portfolios.map((item) => {
                                  return {
                                    name: item.name,
                                    label: item.name,
                                  };
                                })}
                              />
                            </Box>
                          )}
                        {!isEmpty(locations) && (
                          <Box sx={{ whiteSpace: "nowrap" }}>
                            <FormCheckboxGroup
                              name={"location"}
                              help=""
                              label={t(
                                "project.plans.lessons.filter.fields.location.title",
                              )}
                              items={locations.map((location) => {
                                return {
                                  name: location.name,
                                  label: location.name,
                                };
                              })}
                            />
                          </Box>
                        )}
                        {!isEmpty(types) && (
                          <Box sx={{ whiteSpace: "nowrap" }}>
                            <FormCheckboxGroup
                              name={"projectType"}
                              help={""}
                              label={t(
                                "project.plans.lessons.filter.fields.type.title",
                              )}
                              items={types.map((type) => {
                                return {
                                  name: type.name,
                                  label: type.name,
                                };
                              })}
                            />
                          </Box>
                        )}
                        {!isEmpty(departments) && (
                          <Box sx={{ whiteSpace: "nowrap" }}>
                            <FormCheckboxGroup
                              name={"department"}
                              help={""}
                              label={t(
                                "project.plans.lessons.filter.fields.department.title",
                              )}
                              items={departments.map((item) => {
                                return {
                                  name: item.name,
                                  label: item.name,
                                };
                              })}
                            />
                          </Box>
                        )}
                        {!isEmpty(categories) && (
                          <Box sx={{ whiteSpace: "nowrap" }}>
                            <FormCheckboxGroup
                              name={"category"}
                              help={""}
                              label={t(
                                "project.plans.lessons.filter.fields.category.title",
                              )}
                              items={categories.map((item) => {
                                return {
                                  name: item.name,
                                  label: item.name,
                                };
                              })}
                            />
                          </Box>
                        )}
                        <Stack direction="column" sx={{ whiteSpace: "nowrap" }}>
                          <FormCheckboxGroup
                            name={"time"}
                            help={""}
                            label={t(
                              "project.plans.lessons.filter.fields.time.title",
                            )}
                            items={[
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.time.options.lessThan12Months",
                                ),
                                name: "newer",
                                value: newer,
                              },
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.time.options.oneToThreeYears",
                                ),
                                name: "between1and3",
                                value: between1and3,
                              },
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.time.options.threeToFiveYears",
                                ),
                                name: "between3and5",
                                value: between3and5,
                              },
                              {
                                label: t(
                                  "project.plans.lessons.filter.fields.time.options.moreThanFiveYears",
                                ),
                                name: "older",
                                value: older,
                              },
                            ]}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction="row">
                        <Typography mt={1} mr={2}>
                          {t("project.plans.lessons.filter.containsKeywords")} :
                        </Typography>
                        <Box component="span" className={"page-search"}>
                          <Box>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                                sx: {
                                  height: "42px",
                                },
                              }}
                              variant="outlined"
                              type={"text"}
                              onChange={(event) => {
                                updateQuery(event.target.value);
                                setSearchedLessons(
                                  lessons
                                    .filter((item) =>
                                      item?.title
                                        ?.toLowerCase()
                                        ?.includes(
                                          event.target.value.toLowerCase(),
                                        ),
                                    )
                                    .map((item) => item.id),
                                );
                              }}
                              value={query}
                              placeholder={t(
                                "accountSetting.userAdministration.filter.placeholder",
                              )}
                            />
                          </Box>
                        </Box>
                      </Stack>
                    </Stack>
                  </form>
                </FormDataProvider>
              )}
            </Collapse>
          </Stack>
        ) : null}
        {searchForLessons && !showAppliedOnly && <Box mt={2} />}
        <CustomAccordion
          tableView
          header={{
            expanded: false,
            columns: [
              {
                title: "Id",
                width: 50,
              },
              {
                title: t("project.plans.lessons.form.title.title"),
                flex: 1,
              },
              {
                content: (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5">
                      {t("project.plans.lessons.form.priority.title")}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        handleSort("priority");
                      }}
                    >
                      {sortBy.sortedOn === "priority" && sortBy.sortAsc ? (
                        <ExpandMoreIcon />
                      ) : sortBy.sortedOn === "priority" && !sortBy.sortAsc ? (
                        <ExpandLess />
                      ) : (
                        <UnfoldMore />
                      )}
                    </IconButton>
                  </Stack>
                ),
                flex: 1,
              },
              {
                title: t("project.plans.lessons.form.recommendations.title"),
                flex: 1,
              },
              {
                content: (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h5">
                      {t("project.plans.lessons.form.date.title")}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        handleSort("date");
                      }}
                    >
                      {sortBy.sortedOn === "date" && sortBy.sortAsc ? (
                        <ExpandMoreIcon />
                      ) : sortBy.sortedOn === "date" && !sortBy.sortAsc ? (
                        <ExpandLess />
                      ) : (
                        <UnfoldMore />
                      )}
                    </IconButton>
                  </Stack>
                ),
                flex: 1,
              },
            ],
          }}
          rows={(sortedLessons ?? []).map((lesson, index) => ({
            id: lesson.id,
            expanded: editingId === lesson.id,
            sx: {
              "& .MuiAccordionSummary-root": {
                opacity: lesson?.archived?.includes(id) ? 0.5 : 1,
                textDecoration: lesson?.archived?.includes(id)
                  ? "line-through"
                  : "initial",
              },
            },
            onClick: () => {
              if (editingId === lesson?.id) {
                setEditingId(null);
              } else {
                setEditingId(lesson?.id);
              }
            },
            columns: [
              {
                title: index + 1 + " ",
                width: 50,
              },
              {
                title: lesson.title,
                flex: 1,
              },
              {
                title: t(
                  `project.plans.lessons.form.priority.options.${lesson.priority}`,
                ),
                flex: 1,
              },
              {
                content: (
                  <Typography
                    dangerouslySetInnerHTML={{ __html: lesson.recommendations }}
                  />
                ),
                flex: 1,
              },
              {
                content: (
                  <DatePretty date={lesson.identified} format="yyyy-MM-DD" />
                ),
                flex: 1,
              },
            ],
            details: (
              <LessonDetails
                projectData={projectData}
                lesson={lesson}
                onSubmit={handleUpdateLesson}
                onDelete={() => {
                  deleteItem.mutate({
                    useRestApiV1,
                    projectId: id,
                    lessonId: lesson.id,
                  });
                  refetchProjectLessons();
                }}
                refetch={() => refetchProjectLessons()}
                readOnly={readOnly}
                projects={projectDataList.map((item) => {
                  return {
                    name: item.name,
                    id: item.uri.replace("/projects/", ""),
                  };
                })}
              />
            ),
          }))}
        />
        <LessonForm
          open={showNewItemForm}
          projectData={projectData}
          onCancel={() => setShowNewItemForm(false)}
          onSubmit={handleCreateLesson}
        />
      </Section>
    </Page>
  );
};
