import React from "react";
import { Switch } from "@mui/material";
import { Section } from "../../layout/Section";
import { CustomAccordion } from "../../layout/CustomAccordion";
import businessCaseLogo from "../../assets/images/business_case_s1.png";
import { useQ } from "../../hooks/useQ";
import { project } from "../../queries";
import { useToast } from "../../hooks/useToast";
import { useMutation } from "react-query";
import { Page } from "../../layout/Page";
import { useParams } from "react-router-dom";

export const ProjectSettings = () => {
  const toast = useToast();
  const { id = "" } = useParams();

  const {
    data: projectData = {},
    refetch,
    isLoading,
  } = useQ(`project-${id}`, () => project.single({ id }));

  const updateBusinessCase = useMutation(project.toggleBusinessCase, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Access updated successfully");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating access");
    },
  });

  return (
    <Page isLoading={isLoading} title={"Business case"}>
      <Section title="Business case">
        <CustomAccordion
          rows={[
            {
              id: 1,
              title: "Business case",
            },
          ].map((row) => ({
            id: row.id,
            expanded: false,
            expandDisabled: true,
            columns: [
              { content: <img alt="" src={businessCaseLogo} />, flex: 1 },
              {
                content: (
                  <Switch
                    checked={projectData.properties?.businessCase}
                    onClick={() =>
                      updateBusinessCase.mutate({
                        id,
                        data: {
                          useBusinessCase:
                            !projectData.properties?.businessCase,
                        },
                      })
                    }
                  />
                ),
              },
            ],
          }))}
          sx={{ mt: 3 }}
        />
      </Section>
    </Page>
  );
};
