import React, { useEffect, useMemo } from "react";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { PrimaryButton, SecondaryButton } from "../../base/Button";
import { FormDataProvider } from "../../base/form/data-context/FormDataContext";
import * as Yup from "yup";
import { useT } from "../../hooks/useT";
import { useMutation } from "react-query";
import {
  portfolio,
  project,
  location,
  projectType,
  category,
  department,
  currency,
  programme,
} from "../../queries";
import { uris } from "../../config/nav";
import { clientUrl } from "../../util/routing";
import { Hide } from "../../base/responsive/Hide";
import { NoMobileView } from "../../base/responsive/NoMobileView";
import { useQ } from "../../hooks/useQ";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "../../base/form/mui/FormTextField";
import { FormSelect } from "../../base/form/mui/FormSelect";
import { Stack, Grid } from "@mui/material";
import { useToast } from "../../hooks/useToast";

export const EditProperties = () => {
  const { t } = useT();
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { isLoading, data: projectData = {} } = useQ(`project-${id}`, () =>
    project.single({ id }),
  );

  const { data: portfolios = [], isLoading: isLoadingPortfolios } = useQ(
    `portfolios`,
    () => portfolio.list(),
  );
  const { data: programmes = [], isLoading: isLoadingProgrammes } = useQ(
    `programmes`,
    () => programme.list(),
  );
  const {
    data: locations = [],
    refetch: refetchLocations,
    isLoading: isLoadingLocations,
  } = useQ(`locations`, () => location.list());
  const {
    data: projectTypes = [],
    refetch: refetchProjectTypes,
    isLoading: isLoadingProjectTypes,
  } = useQ(`projectTypes`, () => projectType.list());
  const {
    data: categories = [],
    refetch: refetchCategories,
    isLoading: isLoadingCategories,
  } = useQ(`categories`, () => category.list());

  const {
    data: departments = [],
    refetch: refetchDepartments,
    isLoading: isLoadingDepartments,
  } = useQ(`departments`, () => department.list());
  const {
    data: currencies = [],
    refetch: refetchCurrencies,
    isLoading: isLoadingCurrencies,
  } = useQ(`currencies`, () => currency.list());

  function getOptions(array) {
    return array.map((e) => {
      return { label: e.name, value: e.id };
    });
  }

  const editProperties = useMutation(project.editProperties, {
    onSuccess: (data) => {
      navigate(clientUrl(uris.project.single, { id: projectData.id }));
    },
  });

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t("initiative.addEditForm.fields.name.requiredError")),
    identifier: Yup.string().trim(),
    portfolio: Yup.string().trim(),
    location: Yup.string().trim(),
    projectType: Yup.string().trim(),
    category: Yup.string().trim(),
    department: Yup.string().trim(),
    currency: Yup.string().trim(),
    background: Yup.string().trim(),
    timing: Yup.string().trim(),
    bigPicture: Yup.string().trim(),
    programme: Yup.string().trim(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: projectData?.name ?? "",
      identifier: projectData?.identifier ?? "",
      portfolio: projectData?.portfolio ?? "",
      location: projectData?.location ?? "",
      projectType: projectData?.projectType ?? "",
      category: projectData?.category ?? "",
      department: projectData?.department ?? "",
      currency: projectData?.currency ?? "",
      programme: projectData.programme ?? "none",
    },
  });
  const { handleSubmit, reset, control, setValue } = formData;

  useEffect(() => {
    reset({
      name: projectData.name,
      identifier: projectData.identifier,
      portfolio: projectData.portfolio,
      location: projectData.location,
      projectType: projectData.projectType,
      category: projectData.category,
      department: projectData.department,
      currency: projectData.currency,
      programme: projectData.programme?.length ? projectData.programme : "none",
    });
  }, [reset, projectData]);

  return (
    <Page>
      <NoMobileView show={"sm"} />

      <Hide hide={"sm"}>
        <Section title={t("project.editProperties.title")}>
          <FormDataProvider
            formKey="initiative.addEditForm.fields"
            {...formData}
          >
            <form
              onSubmit={handleSubmit((data) => {
                editProperties.mutate({
                  id: projectData.id,
                  data: {
                    ...data,
                    programme: data.programme === "none" ? "" : data.programme,
                  },
                });
              })}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormTextField name="name" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormTextField name="identifier" />
                </Grid>

                {portfolios.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="portfolio"
                      options={getOptions(portfolios)}
                    />
                  </Grid>
                ) : null}

                {locations.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="location"
                      options={getOptions(locations)}
                    />
                  </Grid>
                ) : null}

                {projectTypes.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="projectType"
                      options={getOptions(projectTypes)}
                    />
                  </Grid>
                ) : null}

                {departments.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="department"
                      options={getOptions(departments)}
                    />
                  </Grid>
                ) : null}

                {categories.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="category"
                      options={getOptions(categories)}
                    />
                  </Grid>
                ) : null}

                {currencies.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="currency"
                      options={currencies.map((e) => {
                        return { label: e, value: e };
                      })}
                    />
                  </Grid>
                ) : null}

                {programmes.length > 0 ? (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      name="programme"
                      options={[
                        {
                          label: t(
                            "initiative.addEditForm.fields.programme.none",
                          ),
                          value: "none",
                        },
                        ...programmes.map((programme) => {
                          return {
                            label: `${t(
                              "initiative.addEditForm.fields.programme.present",
                            )}${programme.name}`,
                            value: programme.id,
                          };
                        }),
                      ]}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Stack mt={2} direction="row" justifyContent="flex-end">
                <SecondaryButton
                  onClick={() =>
                    navigate(
                      clientUrl(uris.project.single, { id: projectData.id }),
                    )
                  }
                >
                  {t("generic.button.cancel")}
                </SecondaryButton>
                <PrimaryButton className={"mg-l-nm"} type={"submit"}>
                  {t("project.editProperties.update")}
                </PrimaryButton>
              </Stack>
            </form>
          </FormDataProvider>
        </Section>
      </Hide>
    </Page>
  );
};
