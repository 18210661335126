import React, { useState, useCallback, useEffect } from "react";
import { Page } from "@/layout/Page";
import { Section } from "@/layout/Section";
import { Icon } from "@/base/Icon";
import { Div } from "@/base/Div";
import { useT } from "@/hooks/useT";
import { useQ } from "@/hooks/useQ";
import { users } from "@/queries";
import moment from "moment";
import { ProfileIcon } from "@/base/ProfileIcon";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { FormSelect } from "@/base/form/mui/FormSelect";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import {
  Typography,
  Stack,
  Box,
  TextField,
  IconButton,
  Button,
  Paper,
  InputAdornment,
  Pagination,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { CustomAccordion } from "@/layout/CustomAccordion";
import SearchIcon from "@mui/icons-material/Search";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { DeleteButton } from "@/base/DeleteButton";
import { CustomDialog } from "@/layout/CustomDialog";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import {
  EditOutlined,
  AddCircleOutlineOutlined,
  ExpandMore,
  ExpandLess,
  UnfoldMore,
} from "@mui/icons-material";
import { useFlag } from "@/hooks/useFlag";
import {
  splitContact,
  updateForm,
} from "@/pages/projects/forms/OrganizationForm";

function sortArray(array, reverse = false, sortby) {
  var sortedArray = array;
  switch (sortby) {
    case "name":
      if (reverse) {
        sortedArray.sort((a, b) => b.name.localeCompare(a.name));
      } else {
        sortedArray.sort((a, b) => a.name.localeCompare(b.name));
      }
      break;
    case "access":
      if (reverse) {
        sortedArray.sort((a, b) => b.access.localeCompare(a.access));
      } else {
        sortedArray.sort((a, b) => a.access.localeCompare(b.access));
      }
      break;
    case "status":
      if (reverse) {
        sortedArray.sort((a, b) => b.status.localeCompare(a.status));
      } else {
        sortedArray.sort((a, b) => a.status.localeCompare(b.status));
      }
      break;
    case "lastActive":
      if (reverse) {
        sortedArray.sort(
          (a, b) =>
            b.lastActive?.localeCompare(a.lastActive) ||
            (a.lastActive && b.lastActive === undefined),
        );
      } else {
        sortedArray.sort(
          (a, b) =>
            a.lastActive?.localeCompare(b.lastActive) ||
            (b.lastActive && a.lastActive === undefined),
        );
      }
      break;
    case "user":
      if (reverse) {
        sortedArray.sort(function (x, y) {
          return Number(x.isUser) - Number(y.isUser);
        });
      } else {
        sortedArray.sort(function (x, y) {
          return Number(y.isUser) - Number(x.isUser);
        });
      }
      break;
    default:
      break;
  }

  console.log(sortedArray);
  return sortedArray;
}

function searchArray(array, searchWord) {
  let filteredArray = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i].name.toLowerCase().match(searchWord.toLowerCase()))
      filteredArray.push(array[i]);
  }
  if (filteredArray.length == 0) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].email.toLowerCase().match(searchWord.toLowerCase()))
        filteredArray.push(array[i]);
    }
  }
  return filteredArray;
}

function changeAccess(array, id, value) {
  const user = array.find((element) => element.id == id);
  user.access = value;

  return array;
}

const AccessSelector = ({ margin, onToggle, access }) => {
  return (
    <Div
      style={{
        backgroundColor: "#FFFFFF",
        width: "100px",
        height: "62px",
        position: "absolute",
        margin: margin,
        boxShadow:
          "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        borderRadius: "6px",
        zIndex: 999,
      }}
    >
      {" "}
      <Typography
        variant="h4"
        onClick={access !== "admin" ? onToggle : null}
        className="link"
        style={{
          textAlign: "center",
          paddingTop: "5px",
          color: access !== "admin" ? "#374151" : "#D1D5DB",
        }}
      >
        Admin
      </Typography>
      <Typography
        variant="h4"
        onClick={access == "admin" ? onToggle : null}
        className="link"
        style={{
          textAlign: "center",
          paddingTop: "5px",
          color: access == "admin" ? "#374151" : "#D1D5DB",
        }}
      >
        Member
      </Typography>
    </Div>
  );
};

const InviteUser = () => {
  const [query, updateQuery] = useState("");
  const [access, toggleAccess] = useState(false);
  const [userAccess, updateUserAccess] = useState("member");
  return (
    <Box component="span" className={"page-search"}>
      <TextField
        type={"text"}
        onChange={(event) => updateQuery(event.target.value)}
        value={query}
        placeholder={"Email, comma seperated"}
        className={"page-search-input users-page"}
        style={{
          color: "black",
          paddingLeft: "5px",
          width: "60%",
          paddingRight: "130px",
          zIndex: 1,
          position: "relative",
        }}
        InputProps={{
          style: {
            height: "15px",
            border: "none",
          },
          disableUnderline: true,
        }}
        onKeyDown={(event) =>
          event.key === "Enter" ? performSearch(query) : null
        }
      />
      <Box
        onClick={() => toggleAccess(!access)}
        className="link"
        style={{
          width: "130px",
          marginLeft: "58%",
          marginTop: "-45px",
          zIndex: 2,
          position: "relative",
        }}
      >
        <Typography sx={{ float: "left", mt: 1.6 }}>
          as a {userAccess}
        </Typography>
        <Icon
          style={{ float: "left", marginTop: "16px", marginLeft: "5px" }}
          i={"chevron-down"}
        />
        {access ? (
          <AccessSelector
            onToggle={() =>
              updateUserAccess(userAccess !== "admin" ? "admin" : "member")
            }
            margin={"40px 0px 0px 0px"}
            access={userAccess}
          ></AccessSelector>
        ) : null}
      </Box>
      <button
        style={{
          marginLeft: "45px",
          marginTop: "5px",
          height: "39px",
          position: "absolute",
        }}
        className="btn btn-primary mg-l-nm"
      >
        Invite to team
      </button>
    </Box>
  );
};

const Search = ({
  userArray,
  updateSortedArray,
  checked,
  changeChecked,
  t,
}) => {
  const [query, updateQuery] = useState("");
  return (
    <Stack>
      <Stack direction={"row"} spacing={2}>
        <Box component="span" className={"page-search"}>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  height: "42px",
                },
              }}
              variant="outlined"
              type={"text"}
              onChange={(event) => {
                updateQuery(event.target.value);
                updateSortedArray(searchArray(userArray, event.target.value));
              }}
              value={query}
              placeholder={t(
                "accountSetting.userAdministration.filter.placeholder",
              )}
            />
          </Box>
        </Box>
        <FormControlLabel
          label={t("accountSetting.userAdministration.filter.showStakeholders")}
          control={
            <Checkbox
              checked={checked}
              onChange={() => changeChecked(!checked)}
            />
          }
        />
      </Stack>
      {searchArray(userArray, query).length == 0 ? (
        <Typography
          style={{
            color: "red",
            float: "left",
            marginTop: "13px",
          }}
        >
          {t("accountSetting.userAdministration.filter.notFound")}
        </Typography>
      ) : null}
    </Stack>
  );
};

const dateToLastActive = (date, t) => {
  if (date == undefined || date == "")
    return t("accountSetting.userAdministration.fields.noActivity");
  var d = moment(date).utc().format("YYYY-MM-DD HH");
  return moment(d, "YYYY-MM-DD HH").fromNow();
};

export const UserAdministration = () => {
  const { data: userList = [], refetch } = useQ(`users-all`, () => users.all());
  const { t } = useT();
  const [sortedBy, updateSortedBy] = useState(false);
  const [sortedAfter, updateSortedAfter] = useState("");
  const [sortedArray, updateSortedArray] = useState(userList);
  const maxFields = 20;
  const [page, setPage] = useState(1);
  const toast = useToast();

  const [openItemId, setOpenItemId] = useState(null);
  const [editItemId, setEditItemId] = useState(null);
  const [showNewItemForm, setShowNewItemForm] = useState(false);

  const [showStakeholder, setShowStakeholder] = useState(true);

  useEffect(() => {
    updateSortedArray(userList);
  }, userList);

  useEffect(() => {
    if (
      page >
      (sortedArray.length < 1
        ? Math.ceil(userList.length / maxFields)
        : Math.ceil(sortedArray.length / maxFields) ?? 0)
    ) {
      setPage(1);
    }
  }, sortedArray);

  const updateAccess = useMutation(users.updateAccess, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Access updated successfully");
      refetch();
      setEditItemId(null);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating access");
    },
  });

  const addUser = useMutation(users.add, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Successfully added user");
      refetch();
      setShowNewItemForm(false);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error adding user");
    },
  });

  const deletePortfolios = useMutation(users.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("User deleted successfully");
      refetchPortfolios();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting user");
    },
  });

  const handleShowEditItemForm = useCallback((itemId) => {
    setEditItemId(itemId);
  }, []);

  const renderSortingIcon = (field) => {
    return (
      <IconButton
        onClick={() => {
          updateSortedArray([...sortArray(userList, sortedBy, field)]);
          updateSortedBy(!sortedBy);
          updateSortedAfter(field);
        }}
      >
        {field === sortedAfter && sortedBy ? (
          <ExpandMore />
        ) : field === sortedAfter && !sortedBy ? (
          <ExpandLess />
        ) : (
          <UnfoldMore />
        )}
      </IconButton>
    );
  };

  const useAddUser = useFlag("users.add");

  return (
    <Page>
      <Section title={t("accountSetting.userAdministration.title")}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: t("accountSetting.userAdministration.description"),
          }}
        />
      </Section>
      {/*<Box sx={{ marginLeft: "35px", marginBottom: "80px" }}>
        <InviteUser />
  </Box>*/}
      <Box sx={{ marginBottom: "10px" }}>
        <Search
          userArray={userList}
          updateSortedArray={updateSortedArray}
          checked={showStakeholder}
          changeChecked={setShowStakeholder}
          t={t}
        />
      </Box>
      {useAddUser && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          mb={1}
        >
          <Button
            variant="text"
            startIcon={<AddCircleOutlineOutlined />}
            onClick={() => setShowNewItemForm((s) => !s)}
          >
            {showNewItemForm
              ? t("generic.button.close")
              : t("accountSetting.userAdministration.add")}
          </Button>
        </Stack>
      )}
      <CustomDialog
        isOpen={showNewItemForm}
        title={t("accountSetting.userAdministration.add")}
        onClose={() => setShowNewItemForm(false)}
      >
        <UserForm
          onClose={() => setShowNewItemForm(false)}
          onSave={(data) => {
            addUser.mutate(data);
          }}
        />
      </CustomDialog>
      <CustomAccordion
        tableView
        header={{
          columns: [
            {
              title: t("accountSetting.userAdministration.fields.name.title"),
              flex: 3,
              content: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h3">
                    {t("accountSetting.userAdministration.fields.name.title")}
                  </Typography>
                  {renderSortingIcon("name")}
                </Stack>
              ),
            },
            {
              title: t("accountSetting.userAdministration.fields.access.title"),
              flex: 1,
              content: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h3">
                    {t("accountSetting.userAdministration.fields.access.title")}
                  </Typography>
                  {renderSortingIcon("access")}
                </Stack>
              ),
            },
            {
              title: t("accountSetting.userAdministration.fields.status.title"),
              flex: 1,
              content: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h3">
                    {t("accountSetting.userAdministration.fields.status.title")}
                  </Typography>
                  {renderSortingIcon("status")}
                </Stack>
              ),
            },
            {
              title: t("accountSetting.userAdministration.fields.lastActive"),
              flex: 1,
              content: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h3">
                    {t("accountSetting.userAdministration.fields.lastActive")}
                  </Typography>
                  {renderSortingIcon("lastActive")}
                </Stack>
              ),
            },
            {
              title: t("accountSetting.userAdministration.fields.isUser"),
              flex: 1,
              content: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h3">
                    {t("accountSetting.userAdministration.fields.isUser")}
                  </Typography>
                  {renderSortingIcon("user")}
                </Stack>
              ),
            },
          ],
        }}
        rows={(sortedArray.length < 1
          ? userList
              .slice(page * maxFields - maxFields, page * maxFields)
              .filter((item) =>
                showStakeholder ? true : item.access !== "stakeholder",
              )
          : sortedArray
              .filter((item) =>
                showStakeholder ? true : item.access !== "stakeholder",
              )
              .slice(page * maxFields - maxFields, page * maxFields) ?? []
        ).map((element) => ({
          id:
            element.id !== undefined
              ? element.id
              : element.name + element.email,
          expanded: openItemId === element.id,
          onClick: () =>
            setOpenItemId(openItemId !== element.id ? element.id : null),
          columns: [
            {
              content: (
                <>
                  <Stack direction="row">
                    <ProfileIcon
                      name={element.name}
                      sx={{ width: "50px", height: "50px" }}
                    />

                    <Stack sx={{ ml: 1 }}>
                      <Typography fontWeight="bold" variant="h3">
                        {element.name}
                      </Typography>
                      <Typography variant="h4">{element.email}</Typography>
                    </Stack>
                  </Stack>
                </>
              ),
              flex: 3,
            },
            {
              title:
                element.access === "administrator"
                  ? t(
                      "accountSetting.userAdministration.fields.access.options.admin",
                    )
                  : t(
                      "accountSetting.userAdministration.fields.access.options." +
                        element.access,
                    ),
              flex: 1,
            },
            {
              content: (
                <Box
                  sx={{
                    backgroundColor: [
                      element.status === "active" || element.status === "sso"
                        ? "#D1FAE5"
                        : "#E0F2FE",
                    ],
                    borderRadius: "10px",
                    width: "60px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px 15px 7px",
                  }}
                >
                  {element.status === "active" ? (
                    <Typography variant="h5" style={{ color: "#065F46" }}>
                      {t(
                        "accountSetting.userAdministration.fields.status.active",
                      )}
                    </Typography>
                  ) : element.status === "sso" ? (
                    <Typography variant="h5" style={{ color: "#065F46" }}>
                      {t("accountSetting.userAdministration.fields.status.sso")}
                    </Typography>
                  ) : (
                    <Typography variant="h5" style={{ color: "#1E40AF" }}>
                      {t(
                        "accountSetting.userAdministration.fields.status.pending",
                      )}
                    </Typography>
                  )}
                </Box>
              ),
              flex: 1,
            },
            { title: dateToLastActive(element.lastActive, t), flex: 1 },
            {
              content: (
                <Stack
                  fullWidth
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Icon
                    style={{ marginTop: "9px" }}
                    size="md"
                    i={element.isUser ? "user-on" : "user-off"}
                  />
                </Stack>
              ),
              flex: 1,
            },
          ],
          details: (
            <UserDetails
              user={element}
              open={editItemId === element.id}
              onEdit={() => {
                handleShowEditItemForm(element.id);
              }}
              onClose={() => {
                handleShowEditItemForm(null);
              }}
              onSave={(data) => {
                updateAccess.mutate(data);
              }}
              onDelete={() => deletePortfolios.mutate({ id: element.id })}
            />
          ),
        }))}
      />
      <Stack alignItems="center" mt={2}>
        <Pagination
          count={
            sortedArray.length < 1
              ? Math.ceil(
                  userList.filter((item) =>
                    showStakeholder ? true : item.access !== "stakeholder",
                  ).length / maxFields,
                )
              : Math.ceil(
                  sortedArray.filter((item) =>
                    showStakeholder ? true : item.access !== "stakeholder",
                  ).length / maxFields,
                ) ?? 0
          }
          page={page}
          onChange={(event, value) => setPage(value)}
        />
      </Stack>
    </Page>
  );
};

export const UserDetails = ({
  user,
  open,
  onEdit,
  onClose,
  onSave,
  onDelete,
}) => {
  const { t } = useT();

  return (
    <Stack>
      <Stack direction={"row"} spacing={2} justifyContent="flex-end">
        <DeleteButton
          onConfirm={onDelete}
          content="Are you sure you want to delete this user?"
        />
        <Button
          variant="text"
          color="secondary"
          startIcon={<EditOutlined />}
          onClick={onEdit}
        >
          {t("generic.button.edit")}
        </Button>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2">{user.name}</Typography>
        <Typography variant="h3">Email: {user.email}</Typography>
        <Typography variant="h3">
          {t("accountSetting.userAdministration.fields.access.title")}:{" "}
          {user.access === "administrator"
            ? t("accountSetting.userAdministration.fields.access.options.admin")
            : t(
                "accountSetting.userAdministration.fields.access.options." +
                  user.access,
              )}
        </Typography>
        <Typography variant="h3">
          {t("accountSetting.userAdministration.fields.status.title")}:{" "}
          {t("accountSetting.userAdministration.fields.status." + user.status)}
        </Typography>
      </Stack>
      <CustomDialog
        isOpen={open}
        title={`${t("accountSetting.userAdministration.editTitle")} ${
          user.name
        }`}
        onClose={onClose}
      >
        <UserForm
          user={user}
          onClose={onClose}
          onSave={onSave}
          onDelete={onDelete}
        />
      </CustomDialog>
    </Stack>
  );
};

export const UserForm = ({ user, onSave, onClose }) => {
  const { t } = useT();

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    access: yup.string().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      ...(user ?? {}),
      access: user?.access ?? "",
    },
  });

  const { handleSubmit, reset, setValue, getValues } = formData;

  const handleClose = (user) => {
    onClose();
    reset({
      ...user,
    });
  };

  const isnew = !user;

  return (
    <FormDataProvider
      {...formData}
      formKey={"accountSetting.userAdministration.fields"}
    >
      <form
        onSubmit={handleSubmit((data) => {
          onSave({
            id: user?.id || undefined,
            name: data.name,
            email: data.email,
            access: data.access,
            status: data.status,
          });
        })}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ width: "100%", maxWidth: 600 }}
        >
          <FormSelect
            required
            name={"access"}
            options={[
              {
                value: "administrator",
                label: t(
                  "accountSetting.userAdministration.fields.access.options.admin",
                ),
              },
              {
                value: "member",
                label: t(
                  "accountSetting.userAdministration.fields.access.options.member",
                ),
              },
              {
                value: "stakeholder",
                label: t(
                  "accountSetting.userAdministration.fields.access.options.stakeholder",
                ),
              },
            ]}
          />
          <FormTextField
            secondOnChange={(ev) =>
              updateForm(setValue, splitContact(ev.target.value, getValues()))
            }
            name={"email"}
            readOnly={!isnew}
            fullWidth
            required
          />
          <FormTextField name={"name"} readOnly={!isnew} required fullWidth />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ width: "100%", mt: 2 }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClose(user ?? {})}
          >
            {t("generic.button.cancel")}
          </Button>
          <Button type="submit" variant="contained">
            {t("generic.button.save")}
          </Button>
        </Stack>
      </form>
    </FormDataProvider>
  );
};
