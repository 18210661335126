import React from "react";
import { Page } from "../../layout/Page";
import { useT } from "../../hooks/useT";
import { LanguageSwitcher } from "../../layout/LanguageSwitcher";
import { Section } from "../../layout/Section";
import { DraftReportsTable } from "@/base/DraftReportsTable";

export const MyProfile = () => {
  const { t } = useT();
  return (
    <Page>
      <Section title={t("generic.language")}>
        <LanguageSwitcher />
      </Section>
      <Section title={t("page.draftReports.title")}>
        <DraftReportsTable />
      </Section>
    </Page>
  );
};
