import React from "react";
import { Switch } from "@mui/material";
import { Section } from "../../../../layout/Section";
import { CustomAccordion } from "../../../../layout/CustomAccordion";
import businessCaseLogo from "../../../../assets/images/business_case_s1.png";
import { useQ } from "../../../../hooks/useQ";
import { users } from "../../../../queries";
import { useToast } from "../../../../hooks/useToast";
import { useMutation } from "react-query";
import { useFlag } from "../../../../hooks/useFlag";
import { useT } from "@/hooks/useT";

export const ProjectThemes = () => {
  const toast = useToast();
  const { t } = useT();
  const useToggleBusinessCase = useFlag("toggleBusinessCase");

  const { data: businessCase, refetch } = useToggleBusinessCase
    ? useQ(`users-businessCase`, () => users.businessCase())
    : { data: undefined, isFetched: true };

  const updateBusinessCase = useMutation(users.toggleBusinessCase, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Access updated successfully");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating access");
    },
  });

  return (
    <Section title={t("accountSetting.projectSettings.projectThemes.title")}>
      <CustomAccordion
        rows={[
          {
            id: 1,
            title: "Business case",
          },
        ].map((row) => ({
          id: row.id,
          expanded: false,
          expandDisabled: true,
          columns: [
            { content: <img alt="" src={businessCaseLogo} />, flex: 1 },
            {
              content: (
                <Switch
                  checked={businessCase}
                  onClick={() =>
                    useToggleBusinessCase
                      ? updateBusinessCase.mutate({
                          useBusinessCase: !businessCase,
                        })
                      : null
                  }
                />
              ),
            },
          ],
        }))}
        sx={{ mt: 3 }}
      />
    </Section>
  );
};
