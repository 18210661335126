import React from "react";
import { Link } from "react-router-dom";
import { AccordionItem } from "../../base/AccordionItem";
import { DatePretty } from "../../base/DatePretty";
import { Section } from "../../layout/Section";
import { useT } from "@/hooks/useT";

export const FeatureUpdates = () => {
  const updates = [
    {
      title: "Highlight Report",
      subTitle: "Finetuned content",
      date: "2023-05-09",
      description: `Completed highlight report in new design, including pdf generation.`,
    },
    {
      title: "Updated Reports",
      subTitle: "Direct link to initiative",
      date: "2023-05-09",
      description: `Enabled links to initative for each report.`,
    },
    {
      title: "Tailoring",
      subTitle: "Allow different project categories",
      date: "2023-05-02",
      description: `Enabled the administrator to configure different project levels in order to simplify PRINCE2 themes and processes.`,
    },
    {
      title: "Organization",
      subTitle: "Multiple roles per person",
      date: "2023-04-13",
      description: `Allowed one person to have multiple roles in project..`,
    },
    {
      title: "Bulk import",
      subTitle: "Establish link between project and product",
      date: "2022-04-10",
      description: <Link to="/bulkImport">Try out</Link>,
    },
    {
      title: "Updated Design",
      subTitle: "UX and design improvements",
      date: "2022-01-02",
      description: `The design and user experience is updated. The most prominent change is the "Home Page", providing the user with a dashboard.
        This dashboard is showing the most relevant activities and reports, giving the user an opportunity to jump directy to the most
        relevant and interesting information.`,
    },
    {
      title: "PM Name",
      subTitle: "Change of PM in reports",
      date: "2021-12-12",
      description: `When changing the Project Manager for a project, all the historical reports changes name as well instead of showing who
        actually was project manager when the report was published. The posted reports are now snapshot in history with reference
        to the PM at that point in time.`,
    },
    {
      title: "Tabs Removed",
      subTitle: "All report input field on one page",
      date: "2021-05-25",
      description: `All input fields for Highlight, End Stage and End Project Reports on the same page/tab.
         We had user reports that is was cumbersome to toggle tabs when writing highligt reports, end stage reports and end project
         reports. This is now changed. All sections are presented on one screen. The author will now scroll up/down instead of
         switching between tabs.`,
    },
    {
      title: "Risk Status",
      subTitle: "Risk status showns in reports",
      date: "2021-05-25",
      description: `The Action column in the risk section of the highlight reports now includes the heading "Status" followed by comments
        from the "Implement" tab (in edit risk). There is no change to the default Risk Register home screen.`,
    },
  ].filter((item) => item !== undefined);
  const { t } = useT();
  return (
    <Section title={t("generic.newAndUpdated")}>
      {updates.slice(0, 4).map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          subtitle={
            <span>
              {item.subTitle}, <DatePretty date={new Date(item.date)} />
            </span>
          }
        >
          {item.description}
        </AccordionItem>
      ))}
    </Section>
  );
};
