import { CustomAccordion } from "../../../../layout/CustomAccordion";
import { Section } from "../../../../layout/Section";
import { Page } from "../../../../layout/Page";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { FormDataProvider } from "../../../../base/form/data-context/FormDataContext";

import { useQ } from "../../../../hooks/useQ";
import { tailoring, category } from "../../../../queries";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSelect } from "../../../../base/form/mui/FormSelect";
import { useMutation } from "react-query";
import { useToast } from "../../../../hooks/useToast";
import { useT } from "@/hooks/useT";

const maturityLevels = [
  "Level 1: Only Organization theme, no highlight reports and no guidance on starting up activities",
  "Level 2: Without Plan, Business Case and Quality themes",
  "Standard: All PRINCE2 themes are available",
];

function maturityLevelLabel(level, t) {
  if (level === 3) return t("accountSetting.tailoring.fields.standard.title");
  return t(`accountSetting.tailoring.fields.level${level}.title`);
}

const TailoringForm = ({ phase, onSave, onClose, open, state, ...props }) => {
  const { t } = useT();
  const schema = yup.object().shape({
    level: yup.number().required(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      level: phase?.level || 3,
    },
  });

  const { handleSubmit, reset } = formData;

  const options = useMemo(
    () =>
      maturityLevels
        .map((_item, index) => ({
          label: maturityLevelLabel(index + 1, t),
          value: index + 1,
        }))
        .reverse(),
    [],
  );

  return (
    <Paper
      sx={{
        padding: "30px 20px",
        display: open ? "block" : "none",
      }}
      {...props}
    >
      <FormDataProvider formKey="accountSetting.tailoring.fields" {...formData}>
        <form
          onSubmit={handleSubmit((data) => {
            onSave({
              level: data.level,
            });
            state === "add" ? reset() : null;
          })}
        >
          <FormSelect
            name={"level"}
            label={t("accountSetting.tailoring.fields.maturity.title")}
            help=""
            options={options}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              {t("generic.button.cancel")}
            </Button>
            <Button type="submit" variant="contained">
              {t("generic.button.save")}
            </Button>
          </Stack>
        </form>
      </FormDataProvider>
    </Paper>
  );
};

export const Tailoring = () => {
  const { t } = useT();
  const { data: categories = [], refetch: refetchCategories } = useQ(
    `projectTypes`,
    () => category.list(),
  );

  const toast = useToast();

  const tailorCategory = useMutation(tailoring.category, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Category tailored successfully");
      refetchCategories();
      handleShowEditItemForm(null);
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error tailor category");
    },
  });

  const [editItemIds, setEditItemIds] = useState(
    (categories ?? []).map(() => false),
  );

  useEffect(() => {
    setEditItemIds((categories ?? []).map(() => false));
  }, [categories]);

  const handleShowEditItemForm = useCallback((index) => {
    setEditItemIds((editItemIds) => [
      ...editItemIds.slice(0, index),
      !editItemIds[index],
      ...editItemIds.slice(index + 1),
    ]);
  }, []);

  return (
    <Page>
      <Section title={t("accountSetting.tailoring.title")}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Typography>{t("accountSetting.tailoring.description")}</Typography>
        </Stack>
        {categories.map((category, index) => (
          <Box sx={{ width: "100%", mt: 5 }} key={category.id}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2">{`${t(
                "accountSetting.tailoring.for",
              )} "${category.name}"`}</Typography>
            </Stack>
            <Box sx={{ mt: "20px" }}>
              <CustomAccordion
                tableView
                header={{
                  columns: [
                    { title: "Type", flex: 1 },
                    {
                      title: t(
                        "accountSetting.tailoring.fields.description.title",
                      ),
                      flex: 4,
                    },
                  ],
                }}
                rows={(category.level
                  ? [
                      {
                        text: t(
                          "accountSetting.tailoring.fields.maturity.title",
                        ),
                        description: maturityLevelLabel(category.level, t),
                        level: category.level,
                      },
                    ]
                  : [
                      {
                        text: t(
                          "accountSetting.tailoring.fields.maturity.title",
                        ),
                        description: maturityLevelLabel(3, t),
                        level: 3,
                      },
                    ]
                ).map((phase) => ({
                  id: phase.id,
                  columns: [
                    {
                      content: (
                        <Typography sx={{ fontWeight: "bold" }}>
                          {phase.text}
                        </Typography>
                      ),
                      flex: 1,
                      sx: { alignSelf: "flex-start" },
                    },
                    {
                      title:
                        phase.description?.length > 200
                          ? phase.description?.substr(0, 200) + "..."
                          : phase.description,
                      flex: 4,
                    },
                  ],
                  expanded: !!editItemIds[index],
                  onClick: () =>
                    editItemIds[index] !== phase.id
                      ? handleShowEditItemForm(index)
                      : handleShowEditItemForm(index),
                  details: (
                    <TailoringForm
                      open
                      phase={phase}
                      onClose={() => {
                        handleShowEditItemForm(index);
                      }}
                      onSave={(data) => {
                        tailorCategory.mutate({
                          id: category.id,
                          ...data,
                        });
                      }}
                    />
                  ),
                }))}
              />
            </Box>
          </Box>
        ))}
      </Section>
    </Page>
  );
};
