import React from "react";
import { Page } from "../../layout/Page";
import { useT } from "../../hooks/useT";
import { ProjectCards } from "./favorites/ProjectCards";
import { PortfolioCards } from "./favorites/PortfolioCards";
import { FeatureUpdates } from "./FeatureUpdates";
import { UpdatedReports } from "./UpdatedReports";
import { createReportLink } from "../../util/reportLinks";

import { home } from "../../queries";
import { useQ } from "../../hooks/useQ";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";

const Reports = () => {
  const { isLoading, data = [] } = useQ(`reports`, () => home.reports());
  const navigate = useNavigate();
  const { t } = useT();
  return (
    <UpdatedReports
      reports={data}
      onClick={(report) => {
        const reportLink = createReportLink(report);
        if (reportLink !== undefined) {
          if (reportLink.includes("/external")) {
            window.location.href = reportLink;
          } else {
            navigate(reportLink);
          }
        }
      }}
      t={t}
    />
  );
};

export const Favs = () => {
  const { t } = useT();
  const navigate = useNavigate();

  return (
    <Page title={t("page.home.favorites.title")}>
      <ProjectCards
      /*onReportClick={(report) => {
          navigate("/" + createReportLink(report));
        }}*/
      />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Reports />
        </Grid>
        <Grid item xs={12} md={6}>
          <FeatureUpdates />
        </Grid>
      </Grid>

      <PortfolioCards />
    </Page>
  );
};
