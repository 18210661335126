import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Page } from "../../layout/Page";
import { Section } from "../../layout/Section";
import { useT } from "../../hooks/useT";
import { project } from "../../queries";
import { isNil, path, propOr } from "ramda";
import { uris } from "../../config/nav";
import { clientUrl } from "../../util/routing";
import { SummaryCard } from "../../base/project/SummaryCard";
import { Stat } from "../../base/Stat";
import { EntityLink } from "../../base/EntityLink";
import { DataList } from "../../base/DataList";
import { Badge } from "../../base/Badge";
import { BadgeGroup } from "../../base/BadgeGroup";
import { BlockChart } from "../../base/charts/BlockChart";
import { TimelineChart } from "../../base/charts/TimelineChart";
import { statusDef } from "../../util/constants";
import { useQ } from "../../hooks/useQ";
import { useFlag } from "../../hooks/useFlag";

import {
  threatMatrix,
  opportunityMatrix,
  issueMatrix,
} from "../../base/risk/Risk";

import {
  Grid,
  Stack,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Box,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ProjectDefinition } from "./components/ProjectDefinition";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { StatusSummaryCard } from "@/base/StatusSummaryCard";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { RiskThreatsBlockChart } from "@/pages/projects/components/RiskThreatsBlockChart";
import { IssuesBlockChart } from "@/pages/projects/components/IssuesBlockChart";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ProjectDefinitionForm } from "./ProjectDefinitionForm";

function level2AndAbove(level) {
  return isNil(level) || level >= 2;
}

function level3AndAbove(level) {
  return isNil(level) || level >= 3;
}

const InfoSummaryCard = ({ data }) => {
  const { t } = useT();
  const { executive, projectManager, identifier, part } = data;
  return (
    <SummaryCard>
      <Typography variant="h2" textAlign="center" mb={1}>
        {t("project.overview.projectInformation.title")}
      </Typography>
      <DataList
        items={[
          {
            label: t("project.overview.projectInformation.part.label"),
            value: part,
          },
          {
            label: t("project.overview.projectInformation.projectId.label"),
            value: identifier,
          },
          {
            label: t("project.overview.projectInformation.executive.label"),
            value: executive,
          },
          {
            label: t(
              "project.overview.projectInformation.projectManager.label",
            ),
            value: projectManager,
          },
        ].filter((item) => !isNil(item.value))}
      />
    </SummaryCard>
  );
};

export const Activities = ({ id, activities, onRefresh, openDefinition }) => {
  const { t } = useT();
  const navigate = useNavigate();

  const state = activities?.name;
  const action = activities?.task;
  const link = activities?.link;

  const toast = useToast();

  const recommendClosure = useMutation(project.recommendClosure, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project closure recommended");
      onRefresh?.();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error recommending project closure");
    },
  });

  const approveClosure = useMutation(project.approveClosure, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project closed approved");
      onRefresh?.();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error approving project closure");
    },
  });

  const supportedActivities = useMemo(
    () => ({
      appointExecutiveAndPM: clientUrl(uris.project.organization, { id }),
      prepareOutlineBusinessCase: clientUrl(uris.project.businessCase, { id }),
      appointManagementTeam: clientUrl(uris.project.organization, { id }),
      defineProject: "defineProject",
      describeProjectProduct: clientUrl(
        uris.project.quality + "/productDescription",
        { id },
      ),
      planInitiation: clientUrl(uris.project.plans, { id }),

      prepareRiskManagementApproach: clientUrl(uris.project.riskApproach, {
        id,
      }),
      prepareQualityManagementApproach: clientUrl(
        uris.project.quality + "/approach",
        { id },
      ),
      prepareConfigurationManagementApproach: clientUrl(
        uris.project.changeApproach,
        { id },
      ),
      prepareCommunicationManagementApproach: clientUrl(
        uris.project.organizationApproach,
        { id },
      ),
      setupProjectControls: clientUrl(uris.project.plansControls, { id }),
      createProjectPlan: clientUrl(uris.project.plans, { id }),
      selectApproach: clientUrl(uris.project.plansApproach, { id }),
      assemblePID: clientUrl(uris.project.pid, { id }),
      assembleBrief: clientUrl(uris.project.brief, { id }),
      approvePID: clientUrl(uris.project.approvePid, { id }),
      approveProjectBrief: clientUrl(uris.project.approveBrief, { id }),
      captureLessons: clientUrl(uris.project.plansLessons, { id }),
      createLessons: clientUrl(uris.project.plansLessons, { id }),
      createEndProjectReport: clientUrl(uris.project.create_progress, {
        id,
        type: "endProject",
      }),
      closeRiskRegister: clientUrl(uris.project.risk, { id }),
      closeIssueRegister: clientUrl(uris.project.change, { id }),
    }),
    [id],
  );

  const activityCount = {
    [statusDef.inProgress]: propOr([], "status", activities).filter(
      ({ status: s }) => s === statusDef.inProgress,
    ).length,
    [statusDef.done]: propOr([], "status", activities).filter(
      ({ status: s }) => s === statusDef.done,
    ).length,
    [statusDef.notStarted]: propOr([], "status", activities).filter(
      ({ status: s }) => s === statusDef.notStarted,
    ).length,
  };

  const isClosure = state === "closeProject" && action === "closeProject";

  const handleAction = useCallback(() => {
    if (isClosure) {
      if (
        (activities?.status ?? [])?.find(
          (item) => item.name === "recommendClosure",
        )?.status === "done"
      ) {
        approveClosure.mutate({ id });
      } else {
        recommendClosure.mutate({ id });
      }
      return;
    }
    if (supportedActivities[action] !== "defineProject") {
      navigate(supportedActivities[action]);
    }
  }, [navigate, action, supportedActivities, id]);

  const getActionLabel = () => {
    if (isClosure) {
      if (
        (activities?.status ?? [])?.find(
          (item) => item.name === "recommendClosure",
        )?.status === "done"
      ) {
        return t(
          `project.overview.activities.${state}.actions.approveClosure.title`,
        );
      } else {
        return t(
          `project.overview.activities.${state}.actions.recommendClosure.title`,
        );
      }
    }
    return t(`project.overview.activities.${state}.actions.${action}.title`);
  };

  return !isNil(activities) ? (
    <>
      <Section
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
        title={t(`project.overview.activities.${state}.title`)}
      >
        <Stack alignItems="center">
          <BadgeGroup>
            <Badge variant="green" light>
              <Stat status={statusDef.done} />{" "}
              {t("project.overview.activities.done")}:{" "}
              {activityCount[statusDef.done]}
            </Badge>
            <Badge variant="yellow" light>
              <Stat status={statusDef.inProgress} />{" "}
              {t("project.overview.activities.inProgress")}:{" "}
              {activityCount[statusDef.inProgress]}
            </Badge>
            <Badge light>
              <Stat status={statusDef.notStarted} />
              {t("project.overview.activities.notStarted")}:{" "}
              {activityCount[statusDef.notStarted]}
            </Badge>
          </BadgeGroup>
        </Stack>
      </Section>

      <Section
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
        pt={4}
      >
        <Grid container spacing={6}>
          {(activities?.status ?? []).map(({ name, link, status }) => {
            return (
              <Grid item xs={12} md={4} sx={{ pt: "8px !important" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  key={name}
                  spacing={1}
                >
                  <Stat status={status} size={"md"} className={"pd-r-sm"} />
                  {link !== undefined ? (
                    supportedActivities[name] === "defineProject" ? (
                      <Link onClick={openDefinition}>
                        {t(
                          `project.overview.activities.${state}.tasks.${name}.title`,
                        )}
                      </Link>
                    ) : (
                      <Link href={supportedActivities[name]}>
                        {t(
                          `project.overview.activities.${state}.tasks.${name}.title`,
                        )}
                      </Link>
                    )
                  ) : (
                    <EntityLink>
                      {t(
                        `project.overview.activities.${state}.tasks.${name}.title`,
                      )}
                    </EntityLink>
                  )}
                  <Box sx={{ flex: 1 }} />
                  <Tooltip
                    title={t(
                      `project.overview.activities.${state}.tasks.${name}.help`,
                    )}
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Section>

      <Section hide={"sm"}>
        <Button onClick={handleAction} variant="contained" color="secondary">
          {getActionLabel()}
        </Button>
      </Section>
    </>
  ) : null;
};

const RiskAndIssue = ({ projectId, risks, issues }) => {
  const { t } = useT();

  return (
    <SummaryCard>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h2" textAlign="center">
            {t("project.risksAndIssues.threats")}
          </Typography>
          <RiskThreatsBlockChart
            id={projectId}
            type="project"
            threats={threatMatrix(risks)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h2" textAlign="center">
            {t("project.risksAndIssues.opportunities")}
          </Typography>
          <BlockChart
            data={opportunityMatrix(risks)}
            xLabel={t("project.risksAndIssues.impact")}
            yLabel={t("project.risksAndIssues.probability")}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h2" textAlign="center">
            {t("project.risksAndIssues.issues")}
          </Typography>
          <IssuesBlockChart
            id={projectId}
            type="project"
            issues={issueMatrix(issues)}
          />
        </Grid>
      </Grid>
    </SummaryCard>
  );
};

function activitiesAtLevel(activities, level) {
  const level2Activities = [
    "appointExecutiveAndPM",
    "defineProject",
    "assembleBrief",
  ];
  return isNil(activities) || level === 1
    ? undefined
    : level === 2
      ? {
          ...activities,
          status: isNil(activities.status)
            ? []
            : activities.status.filter((activity) =>
                level2Activities.includes(activity.name),
              ),
        }
      : activities;
}

const preview = (text) =>
  text !== undefined && text.length > 800
    ? text.substring(0, text.indexOf(" ", 750)) + " ..."
    : text;

export const Project = () => {
  const { id = "" } = useParams();
  const { t } = useT();
  const navigate = useNavigate();
  const toast = useToast();
  const useFavorite = useFlag("favorites.add");
  const useFavoriteCommand = useFlag("favorites.command");

  const {
    isLoading,
    data = {},
    refetch,
  } = useQ(`project-${id}`, () => project.single({ id }));

  const { activities, background, stage, name } = data;

  const [isFavorite, setFavorite] = useState(false);

  const [showAllDefinition, setShowAllDefinition] = useState(false);

  const [isOpenDefinition, setOpenDefinition] = useState(false);

  const calculateTolerance = (tolerance) => {
    if (!tolerance) {
      return null;
    }
    return (
      parseInt(tolerance.value, 10) *
      {
        day: 1,
        week: 7,
        month: 30,
        year: 365,
      }[tolerance.unit]
    );
  };

  const addFavorite = useMutation(project.addFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project added to favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error adding project to favorites");
    },
  });

  const removeFavorite = useMutation(project.removeFavorite, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project removed from favorites");
      refetch();
    },
    onError: ({ error }) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error removing project from favorites");
    },
  });

  const handleFavoriteToggle = useCallback(() => {
    if (useFavorite) {
      if (isFavorite) {
        removeFavorite.mutate({ id });
      } else {
        addFavorite.mutate({
          id,
          useCommand: useFavoriteCommand,
        });
      }
    } else {
      setFavorite(!isFavorite);
    }
  }, [isFavorite, useFavorite, addFavorite, removeFavorite, id]);

  useEffect(() => {
    if (!useFavorite) {
      return;
    }
    const isFavorite = data?.isFavorite;
    setFavorite(isFavorite);
  }, [data?.isFavorite, useFavorite]);

  return (
    <Page isLoading={isLoading} title={name}>
      <Section
        title={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h1">{name}</Typography>
            <IconButton onClick={handleFavoriteToggle}>
              {isFavorite ? <StarOutlinedIcon /> : <StarBorderOutlinedIcon />}
            </IconButton>
            <span style={{ fontSize: 8 }}>
              {useFavoriteCommand ? " by command" : " regular"}
            </span>
          </Stack>
        }
      >
        <Stack spacing={5}>
          <Stack spacing={2}>
            {!showAllDefinition ? (
              <Typography
                sx={{ mt: "4px" }}
                dangerouslySetInnerHTML={{ __html: preview(background) }}
              />
            ) : (
              <ProjectDefinition isCurrent projectData={data} />
            )}
            <Box>
              <Button
                variant="text"
                endIcon={
                  !showAllDefinition ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )
                }
                onClick={() => setShowAllDefinition((v) => !v)}
              >
                {showAllDefinition
                  ? t("generic.button.read_less")
                  : t("generic.button.read_more")}{" "}
              </Button>
            </Box>
          </Stack>
          <Stack alignItems="center">
            <Button variant="contained" onClick={() => setOpenDefinition(true)}>
              {t("project.overview.goto_edit_definition")}
            </Button>
          </Stack>

          <ProjectDefinitionForm
            onClose={() => setOpenDefinition(false)}
            isOpen={isOpenDefinition}
          />

          <Stack direction="row" spacing={6} px={6}>
            <StatusSummaryCard data={data} type={"projects"} />
            <InfoSummaryCard data={data} />
          </Stack>

          <Activities
            id={id}
            activities={activitiesAtLevel(activities, data.level)}
            onRefresh={() => {
              refetch();
            }}
            openDefinition={() => setOpenDefinition(true)}
          />

          {level2AndAbove(data.level) ? (
            <>
              <RiskAndIssue
                projectId={id}
                risks={data.risks}
                issues={data.issues}
              />

              {level3AndAbove(data.level) ? (
                <SummaryCard>
                  <Stack
                    spacing={3}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 8 }}
                  >
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {t("project.timeline.currentStage")}:{" "}
                        <Typography
                          component="span"
                          sx={{
                            color: "#9A9EB1",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {stage?.name}
                        </Typography>
                      </Typography>
                      {id && stage?.id && (
                        <Button
                          variant="contained"
                          color="secondary"
                          endIcon={<ChevronRightIcon />}
                          onClick={() => {
                            navigate(
                              clientUrl(uris.project.plansStageDetails, {
                                id,
                                stageId: stage?.id,
                              }),
                            );
                          }}
                        >
                          {t("project.timeline.goToStage")}
                        </Button>
                      )}
                    </Stack>

                    <Badge variant={"info"}>
                      <Stat status={"info"} /> ??
                    </Badge>
                  </Stack>
                  <TimelineChart
                    data={{
                      startDate: stage?.time?.start,
                      endDate: stage?.time?.end,
                      tolerance: calculateTolerance(stage?.time?.tolerance),
                    }}
                  />
                </SummaryCard>
              ) : null}
            </>
          ) : null}

          {false && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Section title="Recently Updated Reports">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography variant="h2">Title for this</Typography>
                        <Typography variant="body2">and a subtitle</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ab aspernatur autem culpa cum cumque deserunt dolorem eum
                      facere facilis harum hic id iure nemo odit, possimus quae
                      quasi vel voluptates.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography variant="h2">Title for this</Typography>
                        <Typography variant="body2">and a subtitle</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ab aspernatur autem culpa cum cumque deserunt dolorem eum
                      facere facilis harum hic id iure nemo odit, possimus quae
                      quasi vel voluptates.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography variant="h2">Title for this</Typography>
                        <Typography variant="body2">and a subtitle</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ab aspernatur autem culpa cum cumque deserunt dolorem eum
                      facere facilis harum hic id iure nemo odit, possimus quae
                      quasi vel voluptates.
                    </AccordionDetails>
                  </Accordion>
                </Section>
              </Grid>
              <Grid item xs={12} md={6}>
                <Section title="Recommended Actions">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography variant="h2">Title for this</Typography>
                        <Typography variant="body2">and a subtitle</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ab aspernatur autem culpa cum cumque deserunt dolorem eum
                      facere facilis harum hic id iure nemo odit, possimus quae
                      quasi vel voluptates.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography variant="h2">Title for this</Typography>
                        <Typography variant="body2">and a subtitle</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ab aspernatur autem culpa cum cumque deserunt dolorem eum
                      facere facilis harum hic id iure nemo odit, possimus quae
                      quasi vel voluptates.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack>
                        <Typography variant="h2">Title for this</Typography>
                        <Typography variant="body2">and a subtitle</Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ab aspernatur autem culpa cum cumque deserunt dolorem eum
                      facere facilis harum hic id iure nemo odit, possimus quae
                      quasi vel voluptates.
                    </AccordionDetails>
                  </Accordion>
                </Section>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Section>
    </Page>
  );
};

/**** Translation keys for dynamic Headings
 *
 * Activities:

 t('project.overview.activities.appointExecutiveAndPM.title');
 t('project.overview.activities.captureLessons.title');
 t('project.overview.activities.appointManagementTeam.title');
 t('project.overview.activities.prepareOutlineBusinessCase.title');
 t('project.overview.activities.defineProject.title');
 t('project.overview.activities.describeProjectProduct.title');
 t('project.overview.activities.selectApproach.title');
 t('project.overview.activities.planInitiation.title');
 t('project.overview.activities.assembleBrief.title');
 t('project.overview.activities.prepareRiskManagementApproach.title');
 t('project.overview.activities.prepareQualityManagementApproach.title');
 t('project.overview.activities.prepareConfigurationManagementApproach.title');
 t('project.overview.activities.prepareCommunicationManagementApproach.title');
 t('project.overview.activities.setupProjectControls.title');
 t('project.overview.activities.createProjectPlan.title');
 t('project.overview.activities.refineBusinessCase.title');
 t('project.overview.activities.defineProject.title');
 t('project.overview.activities.describeProjectProduct.title');
 t('project.overview.activities.assemblePID.title');
 t('project.overview.activities.createLessons.title');
 t('project.overview.activities.createEndProjectReport.title');
 t('project.overview.activities.closeRiskRegister.title');
 t('project.overview.activities.closeIssueRegister.title');
 t('project.overview.activities.recommendClosure.title');
 t('project.overview.activities.sponsoringTheProgramme.title');
 t('project.overview.activities.confirmProgrammeMandate.title');
 t('project.overview.activities.appointSROAndProgrammeBoard.title');
 t('project.overview.activities.produceProgrammeBrief.title');
 t('project.overview.activities.developProgrammePreparationPlan.title');
 t('project.overview.activities.independentReview.title');
 t('project.overview.activities.approvalToProceed.title');
 t('project.overview.activities.establishInfrastructure.title');
 t('project.overview.activities.establishTeam.title');
 t('project.overview.activities.indentifyStakeholders.title');
 t('project.overview.activities.refineVision.title');
 t('project.overview.activities.developBlueprint.title');
 t('project.overview.activities.developBenefitProfiles.title');
 t('project.overview.activities.modelBenefits.title');
 t('project.overview.activities.validateBenefits.title');
 t('project.overview.activities.designProjectDossier.title');
 t('project.overview.activities.identifyTranches.title');
 t('project.overview.activities.designProgrammeOrganization.title');
 t('project.overview.activities.developGovernanceArrangements.title');
 t('project.overview.activities.developProgrammePlan.title');
 t('project.overview.activities.confirmBusinessCase.title');
 t('project.overview.activities.consolitateProgrammeDefinition.title');
 t('project.overview.activities.prepareFirstTranche.title');
 t('project.overview.activities.approvalToProceed.title');
 t('project.overview.activities.empatize.title');
 t('project.overview.activities.define.title');
 t('project.overview.activities.ideate.title');
 t('project.overview.activities.prototype.title');
 t('project.overview.activities.test.title');

 * Activities, Help texts:

 t('project.overview.activities.appointExecutiveAndPM.help');
 t('project.overview.activities.captureLessons.help');
 t('project.overview.activities.appointManagementTeam.help');
 t('project.overview.activities.prepareOutlineBusinessCase.help');
 t('project.overview.activities.defineProject.help');
 t('project.overview.activities.describeProjectProduct.help');
 t('project.overview.activities.selectApproach.help');
 t('project.overview.activities.planInitiation.help');
 t('project.overview.activities.assembleBrief.help');
 t('project.overview.activities.prepareRiskManagementApproach.help');
 t('project.overview.activities.prepareQualityManagementApproach.help');
 t('project.overview.activities.prepareConfigurationManagementApproach.help');
 t('project.overview.activities.prepareCommunicationManagementApproach.help');
 t('project.overview.activities.setupProjectControls.help');
 t('project.overview.activities.createProjectPlan.help');
 t('project.overview.activities.refineBusinessCase.help');
 t('project.overview.activities.defineProject.help');
 t('project.overview.activities.describeProjectProduct.help');
 t('project.overview.activities.assemblePID.help');
 t('project.overview.activities.createLessons.help');
 t('project.overview.activities.createEndProjectReport.help');
 t('project.overview.activities.closeRiskRegister.help');
 t('project.overview.activities.closeIssueRegister.help');
 t('project.overview.activities.recommendClosure.help');
 t('project.overview.activities.sponsoringTheProgramme.help');
 t('project.overview.activities.confirmProgrammeMandate.help');
 t('project.overview.activities.appointSROAndProgrammeBoard.help');
 t('project.overview.activities.produceProgrammeBrief.help');
 t('project.overview.activities.developProgrammePreparationPlan.help');
 t('project.overview.activities.independentReview.help');
 t('project.overview.activities.approvalToProceed.help');
 t('project.overview.activities.establishInfrastructure.help');
 t('project.overview.activities.establishTeam.help');
 t('project.overview.activities.indentifyStakeholders.help');
 t('project.overview.activities.refineVision.help');
 t('project.overview.activities.developBlueprint.help');
 t('project.overview.activities.developBenefitProfiles.help');
 t('project.overview.activities.modelBenefits.help');
 t('project.overview.activities.validateBenefits.help');
 t('project.overview.activities.designProjectDossier.help');
 t('project.overview.activities.identifyTranches.help');
 t('project.overview.activities.designProgrammeOrganization.help');
 t('project.overview.activities.developGovernanceArrangements.help');
 t('project.overview.activities.developProgrammePlan.help');
 t('project.overview.activities.confirmBusinessCase.help');
 t('project.overview.activities.consolitateProgrammeDefinition.help');
 t('project.overview.activities.prepareFirstTranche.help');
 t('project.overview.activities.approvalToProceed.help');
 t('project.overview.activities.empatize.help');
 t('project.overview.activities.define.help');
 t('project.overview.activities.ideate.help');
 t('project.overview.activities.prototype.help');
 t('project.overview.activities.test.help');


 * Tasks

 t('project.overview.tasks.approveProjectBrief.title');
 t('project.overview.tasks.approvePID.title');
 t('project.overview.tasks.closeProject.title');

 */
