import { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Stack,
  Box,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import { Section } from "@/layout/Section";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomAccordion } from "@/layout/CustomAccordion";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { FormTextField } from "@/base/form/mui/FormTextField";
import { standardLink } from "@/queries";
import { useQ } from "@/hooks/useQ";
import { useMutation } from "react-query";
import { useToast } from "@/hooks/useToast";
import { FormDataProvider } from "@/base/form/data-context/FormDataContext";
import { DeleteButton } from "@/base/DeleteButton";
import { CustomDialog } from "@/layout/CustomDialog";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";
import { useT } from "@/hooks/useT";

const StandardLinkForm = ({ link, onSaveLink, onClose, open, ...props }) => {
  const { t } = useT();
  const schema = yup.object().shape({
    title: yup.string().required("The amount is required"),
    address: yup.string().required("The address is required"),
    name: yup.string().optional(),
    value: yup.string().optional(),
  });

  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: link?.title || "",
      address: link?.address || "",
      name: link?.parameters?.[0]?.name || "",
      key: link?.parameters?.[0]?.key || "",
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formData;

  useConfirmLeavingPage(isDirty);

  return (
    <CustomDialog
      title={t("accountSetting.projectSettings.standardLinks.form.title")}
      isOpen={open}
      onClose={onClose}
    >
      <FormDataProvider
        formKey="accountSetting.standardLinks.fields"
        {...formData}
      >
        <form
          onSubmit={handleSubmit((data) => {
            onSaveLink({
              id: link?.id || undefined,
              title: data.title,
              address: data.address,
              parameters: [
                {
                  name: data.name,
                  key: data.key,
                },
              ],
            });
            reset();
          })}
        >
          <Typography variant="h2">
            {t("accountSetting.projectSettings.standardLinks.form.link")}
          </Typography>
          <Divider sx={{ maxWidth: 300, mt: 1, mb: 3 }} />
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <FormTextField name={"title"} fullWidth />
            <FormTextField name={"address"} fullWidth />
          </Stack>
          <Typography variant="h2" sx={{ mt: 6 }}>
            {t("accountSetting.projectSettings.standardLinks.form.parameter")}
          </Typography>
          <Divider sx={{ maxWidth: 300, mt: 1, mb: 3 }} />
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <FormTextField name={"name"} fullWidth />
            <FormTextField name={"key"} fullWidth />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ width: "100%", mt: 2 }}
          >
            <Button variant="contained" color="secondary" onClick={onClose}>
              {t("generic.button.cancel")}
            </Button>
            <Button type="submit" variant="contained">
              {link
                ? t("accountSetting.projectSettings.standardLinks.update")
                : t("accountSetting.projectSettings.standardLinks.add")}
            </Button>
          </Stack>
        </form>
      </FormDataProvider>
    </CustomDialog>
  );
};

export const StandardLinks = () => {
  const { t } = useT();
  const toast = useToast();
  const { data: standardLinks = [], refetch: refetchStandardLinks } = useQ(
    `standardLinks`,
    () => standardLink.list(),
  );

  const [showNewLinkForm, setShowNewLinkForm] = useState(false);
  const [editLink, setEditLink] = useState(null);

  const createStandardLinks = useMutation(standardLink.create, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Standard Link created successfully");
      refetchStandardLinks();
      setShowNewLinkForm(false);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating Standard Link");
    },
  });

  const updateStandardLinks = useMutation(standardLink.update, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Standard Link updated successfully");
      refetchStandardLinks();
      setEditLink(null);
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating Standard Link");
    },
  });

  const deleteStandardLinks = useMutation(standardLink.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Standard Link deleted successfully");
      refetchStandardLinks();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting Standard Link");
    },
  });

  return (
    <Section title={t("accountSetting.projectSettings.standardLinks.title")}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography>
          {t("accountSetting.projectSettings.standardLinks.description")}
        </Typography>
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setShowNewLinkForm((s) => !s);
            setEditLink(null);
          }}
        >
          {t("accountSetting.projectSettings.standardLinks.add")}
        </Button>
      </Stack>
      <CustomAccordion
        tableView
        header={{
          expanded: false,
          columns: [
            {
              title: "Title",
              flex: 1,
            },
            {
              title: t(
                "accountSetting.projectSettings.standardLinks.form.address",
              ),
              flex: 2,
            },
            {
              title: t(
                "accountSetting.projectSettings.standardLinks.form.parameter",
              ),
              flex: 1,
            },
            {
              content: null,
              width: 40,
            },
            {
              content: null,
              width: 40,
            },
          ],
          details: (
            <StandardLinkForm
              open={showNewLinkForm}
              onSaveLink={(data) => createStandardLinks.mutate(data)}
              onClose={() => {
                setShowNewLinkForm(false);
              }}
            />
          ),
        }}
        rows={standardLinks.map((link) => ({
          id: link.id,
          expanded: link.id === editLink?.id,
          expandDisabled: true,
          columns: [
            {
              title: link.title,
              flex: 1,
            },
            {
              title: link.address,
              flex: 2,
            },
            {
              title: link.parameters
                .map((param) => `${param.name}=${param.key}`)
                .join(", "),
              flex: 1,
            },
            {
              content: (
                <DeleteButton
                  onConfirm={() => deleteStandardLinks.mutate({ id: link.id })}
                  content="Are you sure to delete this link?"
                  disabled={false}
                  iconMode
                />
              ),
              width: 40,
            },
            {
              content: (
                <IconButton
                  onClick={() => {
                    setEditLink(link);
                    setShowNewLinkForm(false);
                  }}
                >
                  <EditIcon />
                </IconButton>
              ),
              width: 40,
            },
          ],
          details: (
            <StandardLinkForm
              link={link}
              onSaveLink={updateStandardLinks.mutate}
              onClose={() => {
                setEditLink(null);
              }}
              open={editLink?.id === link.id}
            />
          ),
        }))}
        sx={{ mt: 3 }}
      />
    </Section>
  );
};
