import React, { useCallback } from "react";
import { Page } from "../../../../layout/Page";
import { TextsArraySection } from "../TextsArraySection";
import { useMutation } from "react-query";
import { projectType, category } from "../../../../queries";
import { useQ } from "../../../../hooks/useQ";
import { useToast } from "../../../../hooks/useToast";
import { ProjectThemes } from "./ProjectThemes";
import { StandardLinks } from "./StandardLinks";
import { useT } from "@/hooks/useT";

export const ProjectSettings = () => {
  const { t } = useT();
  const { data: projectTypes = [], refetch: refetchProjectTypes } = useQ(
    `projectTypes`,
    () => projectType.list(),
  );
  const { data: categories = [], refetch: refetchCategories } = useQ(
    `categories`,
    () => category.list(),
  );
  const toast = useToast();

  const createProjectTypes = useMutation(projectType.create, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project Type created successfully");
      refetchProjectTypes();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating project type");
    },
  });

  const updateProjectTypes = useMutation(projectType.update, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project Type updated successfully");
      refetchProjectTypes();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating project type");
    },
  });

  const deleteProjectTypes = useMutation(projectType.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Project Type deleted successfully");
      refetchProjectTypes();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting project type");
    },
  });

  const createCategories = useMutation(category.create, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Category created successfully");
      refetchCategories();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating category");
    },
  });

  const updateCategories = useMutation(category.update, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Category updated successfully");
      refetchCategories();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error updating category");
    },
  });

  const deleteCategories = useMutation(category.delete, {
    onSuccess: ({ data }) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Category deleted successfully");
      refetchCategories();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error deleting category");
    },
  });

  return (
    <Page>
      <TextsArraySection
        title={t("accountSetting.projectSettings.types.title")}
        description={t("accountSetting.projectSettings.types.description")}
        items={projectTypes}
        onNewItemSubmit={createProjectTypes.mutate}
        onEditItemSubmit={updateProjectTypes.mutate}
        onDeleteItemSubmit={deleteProjectTypes.mutate}
      />
      <TextsArraySection
        title={t("accountSetting.projectSettings.categories.title")}
        description={t("accountSetting.projectSettings.categories.description")}
        items={categories}
        onNewItemSubmit={createCategories.mutate}
        onEditItemSubmit={updateCategories.mutate}
        onDeleteItemSubmit={deleteCategories.mutate}
      />
      <ProjectThemes />
      <StandardLinks />
    </Page>
  );
};
