import React from "react";
import { useT } from "../../../hooks/useT";
import { portfolio } from "../../../queries";
import { Section } from "../../../layout/Section";
import { SummaryCard } from "../../../base/project/SummaryCard";
import { isNil, propOr } from "ramda";
import { EntityLink } from "../../../base/EntityLink";
import { useQ } from "../../../hooks/useQ";
import { PieChart } from "../../../base/charts/vendor/PieChart";
import { Grid, Stack, Typography, Box, Paper } from "@mui/material";

const pieData = (status) => [
  { value: status?.yellow },
  { value: status?.red },
  { value: propOr(1, "other", status) },
  { value: status?.green },
];

export const PortfolioCard = ({ id, name, status, description }) => {
  const { t } = useT();
  return (
    <Paper>
      <Stack direction="row" sx={{ height: 300 }}>
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#eee" }}
        >
          <Box sx={{ width: 200, height: 200 }}>
            <PieChart data={pieData(status)} />
          </Box>
          <Typography sx={{ color: "#000 !important", fontWeight: "bold" }}>
            {t("page.home.portfolios.performance").toUpperCase()}
          </Typography>
        </Stack>
        <Stack flex={1} justifyContent="space-between" sx={{ py: 6, px: 4 }}>
          <Stack>
            <Typography variant="h2">{name}</Typography>
            <Typography>{description}</Typography>
          </Stack>
          <Stack>
            {!isNil(status) ? (
              <Typography mb={1}>
                Activities in portfolio:{" "}
                {status?.green + status?.yellow + status?.red + status?.other}
              </Typography>
            ) : null}
            <EntityLink icon className={""} entity={"portfolio"} id={id}>
              {t("page.home.portfolios.gotoPortfolio")}
            </EntityLink>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export const PortfolioCards = () => {
  const { t } = useT();
  const { isLoading, data = [] } = useQ(`portfolios`, () => portfolio.list());

  return (
    <Section isLoading={isLoading} title={t("page.home.portfolios.title")}>
      <Grid container spacing={3}>
        {data.map(({ id, name, status, description }, idx) => {
          return (
            <Grid item xs={12} sm={6} key={idx}>
              <PortfolioCard
                key={idx}
                id={id}
                name={name}
                status={status}
                description={description}
              />
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
};
