import React, { memo, useMemo } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { accessObjectByDotSeparatedKeys } from "./util";
import { useFormDataContext } from "../data-context/FormDataContext";
import { TitleWithHelper } from "../../TitleWithHelper";
import { useT } from "../../../hooks/useT";
import { useFormStrings } from "../../../hooks/useFormStrings";

export const FormTextField = memo(
  ({
    name,
    label,
    placeholder,
    help,
    textFieldRef,
    inputRef,
    secondOnChange,
    readOnly,
    fieldKey,
    multiline,
    required,
    ...props
  }) => {
    const { t } = useT();
    const {
      control,
      formState: { errors },
    } = useFormContext();
    const inputStrings = useFormStrings({
      name,
      label,
      placeholder,
      help,
      fieldKey,
    });

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          /**
           * The Controller helps us to switch from uncontrolled to controlled input.
           * The InputPropsCombined enhance the Input with custom styling.
           * The value and onChange are passed from the Controller and interact
           * directly with the react hook form control passed to the Input
           * The ref is both passed to the react hook form, and also to a custom
           * textFieldRef useful to trigger some side effect like .focus()
           */
          return (
            <Stack sx={{ width: "100%" }}>
              {inputStrings.help ? (
                <TitleWithHelper
                  title={inputStrings.label}
                  help={inputStrings.help}
                  required={required}
                />
              ) : inputStrings.label ? (
                <Typography
                  sx={{
                    fontSize: "20px !important",
                    color: "#111827 !important",
                    mb: "12px !important",
                  }}
                >
                  {inputStrings.label}
                  {required && (
                    <Typography
                      component="span"
                      color="error"
                      fontSize="1.3em"
                      lineHeight="1em"
                    >
                      {" "}
                      *
                    </Typography>
                  )}
                </Typography>
              ) : null}
              <TextField
                name={name}
                variant="outlined"
                onChange={(ev) => {
                  onChange(ev.target.value);
                  secondOnChange ? secondOnChange(ev) : null;
                }}
                value={value}
                inputRef={(e) => {
                  ref(e);
                  if (textFieldRef) {
                    textFieldRef.current = e;
                  }
                }}
                onBlur={onBlur}
                error={!!accessObjectByDotSeparatedKeys(errors, name)}
                helperText={
                  accessObjectByDotSeparatedKeys(errors, name)?.message
                }
                inputProps={{
                  readOnly: Boolean(readOnly),
                  disabled: Boolean(readOnly),
                }}
                placeholder={inputStrings.placeholder}
                multiline={multiline}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: multiline ? "auto" : undefined,
                  },
                }}
                {...props}
              />
            </Stack>
          );
        }}
      />
    );
  },
);
FormTextField.displayName = "FormTextField";
